import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List } from 'antd-mobile';
import '../../main/index.css';
const Item = List.Item;

const Three = ({ threeList }) => {
    return (
        <List className="my-list">
            {/* {threeList.map((item, index) => {
                return (
                    <Item className="item" key={index}>
                        <div className="content">
                            <div>{item.checkTime}</div>
                            <div className="status">已整改</div>
                        </div>
                        <div className="content">
                            <div>频率</div>
                            <div>第{item.checkNumber}轮</div>
                        </div>
                        <div className="content">
                            <div>整改项</div>
                            <div>{item.checkFirst}</div>
                        </div>
                        <div className="content">
                            <div>查看详情</div>
                            <div className="status">></div>
                        </div>
                    </Item>
                )
            })} */}
        </List>
    )
}

export default Three;