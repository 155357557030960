import { Component } from 'react'
import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { getEmissionList } from '../../api/wx';
import BooleanChart from './compent/echatzhu';
import NormalBarChart from './compent/echatzhu2';

import "./index.css";

export default class js extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isYuWuPaiYes: 0,
            isYuWuPaiNo: 0,
            isYuWuJieYes: 0,
            isYuWuJieNo: 0,
            isDraYes: 0,
            isDraNo: 0,
            isDrago: 0,
            isPaiYanYes: 0,
            isPaiYanNo: 0,
            isPaiYanSj: 0,
            isFeiUniteYes: 0,
            isFeiUniteNo: 0,
            isFeiUniteSy: 0,
            isCanUniteYes: 0,
            isCanUniteNo: 0,
            isCanUniteNoGq: 0,
            isCerYes: 0,
            isCerNo: 0,
            isOtherYes: 0,
            isOtherNo: 0,
        };
    }
    //项目初始化页面网络请求
    componentDidMount() {
        this.getData()
    }
    getData = () => {
        //优良差数据接口
        getEmissionList().then(data => {
            console.log(data);
            //筛选数据
            let isYuWuPaiYes = 0, isYuWuPaiNo = 0;
            let isYuWuJieYes = 0, isYuWuJieNo = 0;
            let isDraYes = 0, isDraNo = 0, isDrago = 0;
            let isPaiYanYes = 0, isPaiYanNo = 0, isPaiYanSj = 0;
            let isFeiUniteYes = 0, isFeiUniteNo = 0, isFeiUniteSy = 0;
            let isCanUniteYes = 0, isCanUniteNo = 0, isCanUniteNoGq = 0;
            let isCerYes = 0, isCerNo = 0;
            let isOtherYes = 0, isOtherNo = 0;

            let array = data.data['emissionList'];

            if (array.length > 0) {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].isYuWuPai == '是') {
                        isYuWuPaiYes = isYuWuPaiYes + 1
                    }
                    if (array[i].isYuWuPai == '否') {
                        isYuWuPaiNo = isYuWuPaiNo + 1
                    }
                    if (array[i].isYuWuJie == '是') {
                        isYuWuJieYes = isYuWuJieYes + 1
                    }
                    if (array[i].isYuWuJie == '否') {
                        isYuWuJieNo = isYuWuJieNo + 1
                    }
                    if (array[i].isDra == '有') {
                        isDraYes = isDraYes + 1
                    }
                    if (array[i].isDra == '无') {
                        isDraNo = isDraNo + 1
                    }
                    if (array[i].isDra == '共用') {
                        isDrago = isDrago + 1
                    }
                    if (array[i].isPaiYan == '是') {
                        isPaiYanYes = isPaiYanYes + 1
                    }
                    if (array[i].isPaiYan == '否') {
                        isPaiYanNo = isPaiYanNo + 1
                    }
                    if (array[i].isPaiYan == '不涉及') {
                        isPaiYanSj = isPaiYanSj + 1
                    }
                    if (array[i].isFeiUnite == '有') {
                        isFeiUniteYes = isFeiUniteYes + 1
                    }
                    if (array[i].isFeiUnite == '无') {
                        isFeiUniteNo = isFeiUniteNo + 1
                    }
                    if (array[i].isFeiUnite == '不适用') {
                        isFeiUniteSy = isFeiUniteSy + 1
                    }

                    if (array[i].isCanUnite == '有') {
                        isCanUniteYes = isCanUniteYes + 1
                    }
                    if (array[i].isCanUnite == '无') {
                        isCanUniteNo = isCanUniteNo + 1
                    }
                    if (array[i].isCanUnite == '过期') {
                        isCanUniteNoGq = isCanUniteNoGq + 1
                    }

                    if (array[i].isCer == '是') {
                        isCerYes = isCerYes + 1
                    }
                    if (array[i].isCer == '否') {
                        isCerNo = isCerNo + 1
                    }
                    if (array[i].isOther == '是') {
                        isOtherYes = isOtherYes + 1
                    }
                    if (array[i].isOther == '否') {
                        isOtherNo = isOtherNo + 1
                    }


                }

                console.log('isYuWuPaiYes==', isYuWuPaiYes);
                this.setState({
                    isYuWuPaiYes,
                    isYuWuPaiNo,
                    isYuWuJieYes,
                    isYuWuJieNo,
                    isDraYes,
                    isDraNo,
                    isDrago,
                    isPaiYanYes,
                    isPaiYanNo,
                    isPaiYanSj,
                    isFeiUniteYes,
                    isFeiUniteNo,
                    isFeiUniteSy,
                    isCanUniteYes,
                    isCanUniteNo,
                    isCanUniteNoGq,
                    isCerYes,
                    isCerNo,
                    isOtherYes,
                    isOtherNo
                })

            }

        })
    }

    render() {
        const { isYuWuPaiYes,
            isYuWuPaiNo,
            isYuWuJieYes,
            isYuWuJieNo,
            isDraYes,
            isDraNo,
            isDrago,
            isPaiYanYes,
            isPaiYanNo,
            isPaiYanSj,
            isFeiUniteYes,
            isFeiUniteNo,
            isFeiUniteSy,
            isCanUniteYes,
            isCanUniteNo,
            isCanUniteNoGq,
            isCerYes,
            isCerNo,
            isOtherYes,
            isOtherNo } = this.state
        return (
            <div className="parent">

                <NormalBarChart
                    chartTitle={'是否存在雨污混排'}
                    chartId='isYuWuPai'
                    chartX={['是', '否']}
                    chartData={[isYuWuPaiYes, isYuWuPaiNo]} />

                <NormalBarChart
                    chartTitle={'是否存在雨污混接'}
                    chartId='isYuWuJie'
                    chartX={['是', '否']}
                    chartData={[isYuWuJieYes, isYuWuJieNo]} />

                <NormalBarChart
                    chartTitle={'有无排水许可证'}
                    chartId='isDra'
                    chartX={['有', '无', '共用']}
                    chartData={[isDraYes, isDraNo, isDrago]} />

                <NormalBarChart
                    chartTitle={'是否存在违法排放油烟'}
                    chartId='isPaiYan'
                    chartX={['是', '否', '不涉及']}
                    chartData={[isPaiYanYes, isPaiYanNo, isPaiYanSj]} />

                <NormalBarChart
                    chartTitle={'废油脂收运单位'}
                    chartId='isFeiUnite'
                    chartX={['是', '否', '不适用']}
                    chartData={[isFeiUniteYes, isFeiUniteNo, isFeiUniteSy]} />


                <NormalBarChart
                    chartTitle={'餐厨垃圾收运单位'}
                    chartId='isCanUnite'
                    chartX={['是', '否', '过期']}
                    chartData={[isCanUniteYes, isCanUniteNo, isCanUniteNoGq]} />


                <NormalBarChart
                    chartTitle={'是否取得食品经营许可证'}
                    chartId='isCer'
                    chartX={['是', '否']}
                    chartData={[isCerYes, isCerNo]} />

                <NormalBarChart
                    chartTitle={'是否存在其他违规行为'}
                    chartId='isOther'
                    chartX={['是', '否']}
                    chartData={[isOtherYes, isOtherNo]} />

            </div>
        )
    }
}


