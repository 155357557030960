import 'antd-mobile/dist/antd-mobile.css';
import React, { useState,useEffect } from 'react';
import { List, InputItem, DatePicker, Toast, Picker, Button } from 'antd-mobile';
import { Radio, Form} from 'antd';
import { getCompanyName,emissons } from '../../api/wx';
import './index.css';
const Item = List.Item;
const Brief = Item.Brief;

var statu = true;

const EmissionsCompany = (props) => {
    const [companyName,setcompanyName] = useState('');//企业名称
    const [list,setList] = useState([]);//企业名称

    console.log(props);
    //网络请求
    useEffect(() => {
        
    },[])
   
    const onChange =(e) =>{
        setcompanyName(e)
        if(!statu){
            return;
        }    
        statu=false;
        setTimeout(function(){
            statu=true;
            getCompanyName(e).then((data) => {
                let array = data.data;
                if(array.length > 0){
                    //渲染列表
                    setList(array);
                }
        })
        },1000)
    }

    const ItemClick = (value) => {
        console.log('选中的公司==',value);
        sessionStorage.setItem('company',JSON.stringify(value));
        props.history.goBack();
    }
    return (
        <div>
            <List renderHeader={() => '关键字搜索'} className="header">
                <InputItem
                    placeholder="请输入"
                    onChange={onChange}
                    value={companyName}
                ></InputItem>
            </List>

            <List className="my-list2">
            {list.map((item,index)=>{
                return (
                        <Item arrow="horizontal" key={index} multipleLine onClick={()=>ItemClick(item)}>{item.companyOldName} 
                        <Brief>{item.companyBusAds}</Brief>
                    </Item>
                    )
                })
            }
            </List>
        </div>
    )
}

export default EmissionsCompany;