import { weChatLogin, getWxInfo,getWxCompanyInfo } from '../api/wx'
import { setToken } from './auth'
  
const _appId = "wx74e232c0747e54dc"

/**
 * 登陆
 */
const _getWxLogin = function () {
    let uri = window.location.href
    console.log(uri);
    let redirectcode = encodeURIComponent(uri)
    console.log('转码后====');
    console.log(redirectcode);
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${_appId}&redirect_uri=${redirectcode}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`
    if (localStorage.getItem("WxUserInfo") == null || localStorage.getItem("WxUserInfo") == undefined) {
      window.location.href = url
    } else {
      let hrefs = localStorage.getItem("WxUserInfo")
      if (!hrefs.access_token) {
        window.location.href = url
      }
    }
  }
/**
 * 获取微信code，用code换取用户信息
 */
const getWxCode = function (func) {
    let uri = window.location.href
    if (uri.indexOf("code") == -1) {
      _getWxLogin()
      return;
    }
    console.log(decodeURIComponent(uri));
    console.log(new URLSearchParams(decodeURIComponent(uri).split("?")[1]));
    let param, code
    param = new URLSearchParams(uri.split("?")[1])
    console.log('param==',param);
    code = param.get("code")
    console.log('微信端code==',code);
    //根据code换取access_token
    weChatLogin(code).then(res => {
      if(res.errno === 0){
          setToken(res['data'].token);
          //根据access_token换取微信用户信息
          getWxInfo().then(info => {
              localStorage.setItem("WxUserInfo",JSON.stringify(info['data'].userInfo))
              localStorage.removeItem("companyInfo");
              if (info.companyInfo) {
                localStorage.setItem("companyInfo",JSON.stringify(info['data'].companyInfo))
              }
              let Obj={}
              Obj.code=code
              Obj.time=new Date().getTime();
              localStorage.setItem("WxCode",JSON.stringify(Obj))
              func(info)
          });
      }else{
          _getWxLogin()
      }
    })
  
  }
 
/**
 * 获取jsapi_ticket
 */
  const _getjsapiticket = function (p) {
    // let url=`https://api.weixin.qq.com/cgi-bin/ticket/getticket?access_token=${p}&type=jsapi`
    // let url = `/mobile/user/ajaxPromoCode`
    // let res = API.post(url, {
    //   url: p
    // })
    // return res
  }
  
/**
 * 获取N位随机字符串
 * @param {*} len
 */
  const _getrandonstring = function (len) {
    len = len || 32;
    let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
    let maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
      pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
  }
  
  /**
   * 获取微信上传图片
   * @param {*} param
   */
  const _getmedia = function (param) {
    // let url = `/mobile/user/getmedia`
    // let res = API.post(url, param)
    // return res
  }
  
  const dataURLtoBlob = function(urlData) {
    // var arr = dataurl.split(','),
    // mime = arr[0].match(/:(.*?);/)[1],
    // bstr = atob(arr[1]),
    // n = bstr.length,
    // u8arr = new Uint8Array(n);
    // while (n--) {
    //     u8arr[n] = bstr.charCodeAt(n);
    // }
    // return new Blob([u8arr], {
    //     type: mime,
    //     filename:"tmp.jpg"
    // });

    var bytes = window.atob(urlData.split(',')[1]); //去掉url的头，并转换为byte
    //处理异常,将ascii码小于0的转换为大于0
    var ab = new ArrayBuffer(bytes.length);
    var ia = new Uint8Array(ab);
    for(var i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    return new Blob([ab], {
        type: 'image/png'
    });

  }

  const convertBase64UrlToImgFile = function(urlData,fileName,fileType){
    var bytes = window.atob(urlData.split(',')[1]); //转换为byte
    //处理异常,将ascii码小于0的转换为大于0
    var ab = new ArrayBuffer(bytes.length);
    var ia = new Int8Array(ab);
    var i;
    for (i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
    }
    //转换成文件，添加文件的type，name，lastModifiedDate属性
    var blob=new Blob([ab], {type:fileType});
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return blob;
  }
  export {
    _getWxLogin,
    _getjsapiticket,
    _getmedia,
    getWxCode,
    dataURLtoBlob,
    convertBase64UrlToImgFile
  }
  