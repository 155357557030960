import 'antd-mobile/dist/antd-mobile.css';
import React, { useState } from 'react';
import { Badge, Toast,ImagePicker, Tabs } from 'antd-mobile';
import { Image } from 'antd';
import './index.css';

const tabs = [
    { title: <Badge >无法登记</Badge>, sub: 'subcontent' },
    { title: <Badge >如何登记</Badge>, sub: 'subcontent' },
    { title: <Badge >登记事项</Badge>, sub: 'subcontent' },
];


const About = (props) => {
    const [Index, setIndex] = useState(1);
    const checkTab = (tab, index) => {
        if (index == 0) {
            setIndex(1)
        } else if (index == 1) {
            setIndex(2)
        } else if (index == 2) {
            setIndex(3)
        }
    }
    return (
        <Tabs 
            className="mytab" 
            tabs={tabs} 
            initialPage={0}
            animated={false}
            onChange={(tab, index) => checkTab(tab, index)}
            renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
            renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
        >
            {
                Index == 1 && <Image className="myImgshiyong" preview={false} src="https://yfadmin.chuanfu.ltd/profile/DF01B011EDC5D8470E69D38F5BC644F0.png"></Image>
            }
            {
                Index == 2 && <Image className="myImgshiyong" preview={true} src="https://yfadmin.chuanfu.ltd/profile/896FEB2D51092E8BEE9BEFEE4D7DFA37.png"></Image>
            }
            {
                Index == 3 && <Image className="myImgshiyong" preview={true} src="https://yfadmin.chuanfu.ltd/profile/084A160571C3FE576403B72CF3D9F3ED.png"></Image>
            }
            
        </Tabs>
    )
}

export default About;