
import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import { createHashHistory } from 'history';
import Login from '../pages/login';
import Main from '../pages/main';
import Detail from '../pages/detail';
import MyInfo from '../pages/myInfo';
import About from '../pages/about';
import Check from '../pages/check';
import Local from '../pages/local';
import Home from '../pages/home';
import Canchu from '../pages/canchu';
import AdminLogin from '../pages/adminlogin';
import Feiyouzhi from '../pages/feiyouzhi';
import XianChang from '../pages/xianchang';
import ShiPin from '../pages/shipin';
import GeYou from '../pages/geyou';
import YouShui from '../pages/youshui';
import YouYan from '../pages/youyan';
import Emissions from '../pages/emissions';
import EmissionsCompany from '../pages/emissioncompany';
import Weifa from '../pages//weifapaiwu';

const hashHistory = createHashHistory();

const BasicRoute = (props) => {
    return(
        <HashRouter history={hashHistory}>
        <Switch>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/" component={Main}/>
            <Route exact path="/detail/:id" component={Detail}/>
            <Route exact path="/myInfo" component={MyInfo}/>
            <Route exact path="/about" component={About}/>
            <Route exact path="/check" component={Check}/>
            <Route exact path="/home" component={Home} />
            <Route exact path="/canchu" component={Canchu} />
            <Route exact path="/adminlogin" component={AdminLogin} />
            <Route exact path="/local" component={Local}/>
            <Route exact path="/feiyouzhi" component={Feiyouzhi} />
            <Route exact path="/xianchang" component={XianChang} />
            <Route exact path="/shipin" component={ShiPin} />
            <Route exact path="/geyou" component={GeYou} />
            <Route exact path="/youshui" component={YouShui} />
            <Route exact path="/youyan" component={YouYan} />
            <Route exact path="/emissions" component={Emissions} />
            <Route exact path="/emissionscompany" component={EmissionsCompany} />
            <Route exact path="/weifapaiwu" component={Weifa} />
        </Switch>
    </HashRouter>
    )
}
export default BasicRoute;