import 'antd-mobile/dist/antd-mobile.css';
import React, { useState,useEffect } from 'react';
import {getWxCode} from '../../util/wxService'
import { getCompanyInfo,getInfoWxOut,getWxInfo} from '../../api/wx';
import { Card, WingBlank,ImagePicker,Toast, Steps, TextareaItem,Button, WhiteSpace } from 'antd-mobile';
import './index.css';

const MyInfo = (props) => {
    const [company, setCompany] = useState('');
    useEffect(() => {
            if (localStorage.getItem("WxUserInfo") != null && localStorage.getItem("WxUserInfo") != undefined) {
                const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
                if (!wxInfo.companyId) {
                    props.history.push('/login');//跳到登录页
                } else {
                    getCompanyInfo(wxInfo.companyId,wxInfo.openid).then(data =>{
                        //获取公司信息显示
                        if(data['data']){
                            setCompany(data);
                        }else{
                            props.history.push('/login');//跳到登录页
                        }
                    })
                }
            }else{
                getWxCode(() => {
                    if (localStorage.getItem("WxUserInfo") != null && localStorage.getItem("WxUserInfo") != undefined) {
                        const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
                        if (!wxInfo.companyId) {
                            props.history.push('/login');//跳到登录页
                        } else {
                            getCompanyInfo(wxInfo.companyId,wxInfo.openid).then(data =>{
                                //获取公司信息显示
                                if(data['data']){
                                    setCompany(data);
                                }else{
                                    props.history.push('/login');//跳到登录页
                                }
                            })
                        }
                    }
                })

                // props.history.push('/login');//跳到登录页
            }
    },[]);

    //解除绑定
    const onClick = (value) => {
        const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
        getInfoWxOut(wxInfo.openid).then(data =>{
            if(data['data']){
                getWxInfo().then(info => {//这个info里面是没有任何公司信息
                    localStorage.setItem(
                      "WxUserInfo",
                      JSON.stringify(info['data'].userInfo)
                    );
                    localStorage.removeItem("companyInfo");
                    if (info['data'].companyInfo) {
                      localStorage.setItem(
                        "companyInfo",
                        JSON.stringify(info['data'].companyInfo)
                      );
                    }
                    props.history.push('/login');//跳到登录页
                  });
            }
        })
    }
    return (
        <div>
            <WingBlank size="mg">
                <WhiteSpace size="lg" />
                <Card className="mycard">
                    <Card.Header
                        title="绑定公司信息"
                    />
                    <Card.Body>
                        <div>{company != '' ? company['data'].companyName : ''}</div>
                    </Card.Body>
                 <Button type="primary" onClick={onClick}>解除绑定公司</Button>
                </Card>
                <WhiteSpace size="mg" />
            </WingBlank>
        </div>
    )
}

export default MyInfo;