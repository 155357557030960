import React, { useEffect } from 'react';
import * as echarts from "echarts";

const Index = (props) => {
    const operationObj = {};
    operationObj[props.chartId + 'Index'] = 0;
    operationObj[props.chartId + 'Interval'] = undefined;
    operationObj[props.chartId + 'Chart'] = undefined;

    useEffect(() => {
        if (operationObj[props.chartId + 'Chart'] != null && operationObj[props.chartId + 'Chart'] != "" && operationObj[props.chartId + 'Chart'] != undefined) {
            operationObj[props.chartId + 'Chart'].dispose();
        }

        operationObj[props.chartId + 'Chart'] = echarts.init(document.getElementById(props.chartId));
        operationObj[props.chartId + 'Chart'].setOption(getChartOption());
        return componentWillUnmount;
    }, []);

    function componentWillUnmount() {
        if (operationObj[props.chartId + 'Interval']) {
            clearInterval(operationObj[props.chartId + 'Interval']);
        }
        if (operationObj[props.chartId + 'Chart'] && operationObj[props.chartId + 'Chart'].id) {
            operationObj[props.chartId + 'Chart'].dispose();
        }
    }

    function getChartOption() {
		console.log("----------接受数据------------",props.carslist);
        return {
            title: {
                show: true,
                text: props.chartTitle,
                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#000"
                },
            },
            grid: {
                top: '20%',
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            tooltip: {
                trigger: "axis",
                axisPointer: {
                    type: "shadow"
                }
            },
            xAxis: [{
                data: props.yxox,
                axisTick: {
                    alignWithLabel: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#000",
                        width: 1,
                        type: "solid"
                    }
                },
                axisLabel: {
                    fontSize: 12,
                    color: "#000",
                    interval: 0,
                    rotate: 30,
                    formatter: function (value, index) {
                        return value.length > 4 ? value.substring(0, 4) + '...' : value;
                    }
                }
            }],
            yAxis: {
                minInterval: 1,
                axisLabel: {
                    fontSize: 12,
                    color: "#000"
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: "#000",
                        width: 1,
                        type: "solid"
                    }
                }
            },
            series: [
                {
                    data: props.oneList,
                    type: "bar",
                    itemStyle: {
                        color: "#5DBBFF"
                    }
                }
            ]
        }
    }

    return (
        <div className="normal-box p20">
            <div id={props.chartId} style={{ width: '100%', height: '200px' }}>
            </div>
        </div>
    )
}
export default Index;
