import { Component } from 'react'
import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List, Button, ListView, div, SearchBar, Pagination, Badge, Toast, Tabs, Picker } from 'antd-mobile';
import { Image } from 'antd';
import NormalBarChart1 from './compent/echatzhu1';
import NormalBarChart2 from './compent/echatzhu2';
import NormalBarChart3 from './compent/echatzhu3';
import NormalBarChart from './compent/echatzhu';
import { getCertificateDate, getYearList, YouLiangCha,getCheckOne, getCheckTwo, getCheckList, getFeiYouZhi } from '../../api/wx';
import "../shipin/index.css";
const Item = List.Item;
const Brief = Item.Brief;
const tabs = [
    { title: <Badge >已登记</Badge>, sub: 'subcontent' },
    { title: <Badge >未登记</Badge>, sub: 'subcontent' },
];

const tabs1 = [
    { title: <Badge >日</Badge>, sub: 'subcontent' },
    { title: <Badge >周</Badge>, sub: 'subcontent' },
    { title: <Badge >月</Badge>, sub: 'subcontent' },
    { title: <Badge >年</Badge>, sub: 'subcontent' },
];
export default class js extends Component {
    constructor(props) {
        super(props);
        this.state = {
           oneList:[],
           oneArray: ['热食/冷菜/生食制售', '热食/冷食/饮品制售', '热食/冷食/制售', '热食/饮品制售', '热食制售', '生食制品', '糕点类食品制售', '预包装食品','饮品制售'],
           twoList:[],
           You:'',
           Liang:'',
           Cha:'',
           List1:[],
           List2: [],
           List3: [],
           L1:'',
           L2:'',
           L3:''
        };
    }
   //项目初始化页面网络请求
    componentDidMount() {
        this.getCheck()
        this.getData()
        this.getYearData()
    }
    getCheck = () => {
        getCertificateDate().then(data => {
            if (data.errno == 0) {
                var list = data.data
                this.setState({
                    oneList: list.s1,
                    twoList:list.s2,//s2 = [323,4]
                    L1: list.s3[0],
                    L2: list.s3[1],
                    L3: list.s3[2],
                })
            }
        })
        
    }
    getData = () => {
        //优良差数据接口
        YouLiangCha().then(data => {
            console.log(data);
            if (data.errno == 0) {
                this.setState({
                    You: data.data[0],
                    Cha: data.data[1],
                    Liang: data.data[2],
                })
            }

        })
    }
    getYearData =() =>{
        getYearList().then(data => {
            console.log(data);
            if (data.errno == 0) {
                this.setState({
                    List1: data.data,
                })
            }

        })
    }
    render() {
        const { oneList, oneArray,twoList,You,Liang,Cha,List1,List2,List3,L1,L2,L3} = this.state
        console.log(oneList);
        return (
            <div className="boxCon">
                {/* 柱状趋势图 */}
                <div className="pagetitle">食品经营许可证</div>
				{
                    oneList.length > 0 ? 
                        <NormalBarChart chartTitle={'食品经营许可经营范围'} chartId={'storeSizeChart'}
                       yxox={oneArray} oneList={oneList}/> : null
				} 

                <div className="parent">
                    <div className="p1">
                        <div className="p11">食品经营许可范围与实际范围是否一致</div>
                        <div className="p22">
                            <div className="p111">
                                <div>是</div>
                                <div>否</div>
                            </div>
                            <div className="p111">
                                <div className="ps1"></div>
                                <div className="ps2"></div>
                            </div>
                            <div className="p111">
                                <div>{twoList[0]}</div>
                                <div>{twoList[1]}</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="parent">
                    <div className="p1">
                        <div className="p11">卫生状况</div>
                        <div className='bt2'>
                            <div className="manyi">
                                <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/8F1518A4979912F9BF2B9788F9EEE7C0.png"></Image>
                                <div className="you">优</div>
                                <div className="count">{You}</div>
                            </div>
                            <div className="manyi">
                                <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/AF3767A23CF863998D5240AE53F639DE.png"></Image>
                                <div className="you">良</div>
                                <div className="count">{Liang}</div>
                            </div>
                            <div className="manyi">
                                <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/D6FD858FE17C5DBA7FC16957BAEAD350.png"></Image>
                                <div className="you">差</div>
                                <div className="count">{Cha}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="parent">
                    <div className="p1">
                        <div className="p11">店铺总数</div>
                        <div className='bt2'>
                            <div className="manyi">
                                <div className="you1">店铺总数</div>
                                <div className="count">{L1}</div>
                            </div>
                            <div className="manyi">
                                <div className="you1">本月新增</div>
                                <div className="count">{L2}</div>
                            </div>
                            <div className="manyi">
                                <div className="you1">本月关闭</div>
                                <div className="count">{L3}</div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 店铺总趋势图 */}

                {
                    List1.length > 0 ?
                        <NormalBarChart1 chartTitle={'整改情况'} chartId={'storeSizeChart1'}
                         title="店铺总趋势" List={List1} /> : null
                }

                {/* <Button className="btn" type="primary">下载图表</Button> */}

                {/* <div className="nametitle">逾期未整改名单</div> */}
                <div className='history-table-wrap'>
                    <div className="table">
                        {/* <div className="tr">
                            <div className="th th1">序号</div>
                            <div className="th th2">店铺名称</div>
                            <div className="th th3">数量</div>
                            <div className="th th4">逾期时间</div>
                        </div> */}
                        {/* {
                            list.length == 0 ?
                                <div className="nodata" >
                                    暂无数据
                                </div> :
                                list.map((item, index) => {
                                    return (
                                        <div className="tr">
                                            <div className="td td1">{item.id}</div>
                                            <div className="td td2">{item.companyName}</div>
                                            <div className="td td3">1</div>
                                            <div className="td td4">{item.checkTime}</div>
                                        </div>
                                    )
                                })
                        } */}

                    </div>
                </div> 
            </div>
        )
    }
}


