import React, { useEffect } from 'react';
import * as echarts from "echarts";

const Index = (props) => {
    const operationObj = {};
    operationObj[props.chartId + 'Index'] = 0;
    operationObj[props.chartId + 'Interval'] = undefined;
    operationObj[props.chartId + 'Chart'] = undefined;
    // console.log("----------接受数据------子组件--===----",props.chartData);
    useEffect(() => {
      console.log('----useEffect---');
        if (operationObj[props.chartId + 'Chart'] != null && operationObj[props.chartId + 'Chart'] != "" && operationObj[props.chartId + 'Chart'] != undefined) {
            operationObj[props.chartId + 'Chart'].dispose();
        }

        operationObj[props.chartId + 'Chart'] = echarts.init(document.getElementById(props.chartId));
        operationObj[props.chartId + 'Chart'].setOption(getChartOption());
        return componentDidMount;
    }, props.chartData);

    function componentDidMount() {
        if (operationObj[props.chartId + 'Interval']) {
            clearInterval(operationObj[props.chartId + 'Interval']);
        }
        if (operationObj[props.chartId + 'Chart'] && operationObj[props.chartId + 'Chart'].id) {
            operationObj[props.chartId + 'Chart'].dispose();
        }
    }

    function getChartOption() {
		// console.log("----------接受数据-----重新渲染-------",props.chartData);
    // let data = [1,20]
      return {
        title: {
          show: true,
          text: props.chartTitle,
          textStyle: {
            fontSize: 14,
            fontStyle: 'normal',
            fontWeight: 'normal',
            // color: "#eeeeee"
          }
        },
        grid: {
          top: '15%',
          left: '12%',
        //   right: '10%',
          bottom:'20%',
          // containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: "shadow"
          },
        },
        xAxis: {
          show: false,
          type: 'value'
        },
        yAxis: [{
          type: 'category',
          data: props.chartX,
          axisLabel: {
            show: true,
            textStyle: {
              color: 'block'
            },
          },
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
        }],
        series: [{
          data: props.chartData.length == 2 ? [
            {
              value: props.chartData[0],
              itemStyle: {
                color: 'red'
              }
            }, {
              value: props.chartData[1],
              itemStyle: {
                color: 'green'
              }
            }] : [
              {
                value: props.chartData[0],
                itemStyle: {
                  color: 'red'
                }
              }, {
                value: props.chartData[1],
                itemStyle: {
                  color: 'green'
                },
              }, {
                value: props.chartData[2],
                itemStyle: {
                  color: 'blue'
                },
              }],
          type: 'bar',
          // zlevel: 1,
          label: {
            show: true,
            position: 'right',
            color: 'block'
          },
          // barWidth: 20,
          // encode: {
          //   x: 2,
          //   y: 0
          // },
        }]
      }
    }

    return (
        <div className="normal-box p20">
            <div id={props.chartId} style={{ width: '100%', height: '200px' }}>
            </div>
        </div>
    )
}
export default Index;
