import { Component } from 'react'
import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List, Button, Image, ListView, div, SearchBar, Icon, Pagination, Badge, Toast, Tabs, Picker } from 'antd-mobile';
import NormalBarChart1 from './compent/echatzhu1';
import NormalBarChart2 from './compent/echatzhu2';
import NormalBarChart3 from './compent/echatzhu3';
import NormalBarChart from './compent/echatzhu';
import { getQuYu, getCheckOne, getCheckTwo, getCheckList, getFeiYouZhi } from '../../api/wx';
import "./index.css";
const Item = List.Item;
const Brief = Item.Brief;
const tabs = [
    { title: <Badge >已登记</Badge>, sub: 'subcontent' },
    { title: <Badge >未登记</Badge>, sub: 'subcontent' },
];

const tabs1 = [
    { title: <Badge >日</Badge>, sub: 'subcontent' },
    { title: <Badge >周</Badge>, sub: 'subcontent' },
    { title: <Badge >月</Badge>, sub: 'subcontent' },
    { title: <Badge >年</Badge>, sub: 'subcontent' },
];
export default class js extends Component {
    constructor(props) {
        super(props);
        this.state = {
           oneList:[],
           oneArray: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
           twoList: [],
           twoArray: ['污水排放', '废气排放', '餐厨废油脂', '食品经营许可证', '油烟扰民'],
           threeList:[],
           threeArray:['已整改','已驳回','整改中'],
           fourList:[],
           fourArry: ['严重', '较严重','一般'],
           list:[]
        };
    }
   //项目初始化页面网络请求
    componentDidMount() {
        this.getCheck()//获取本年度开单数
        this.getData()//获取未整改列表
    }
    getCheck = () => {
        getCheckOne().then(data => {
            if (data.errno == 0) {
                var list = data.data
                this.setState({
                    oneList: list.s0,
                    fourList: list.s1,//严重程度
                    threeList: list.s2,//整改情况
                    twoList: list.s3,//问题点
                },()=>{
                    // this.getData()//严重程度 问题点 整改情况
                })
            }
        })
    }
    getData = () => {
        getCheckList().then(data => {
            if (data.errno == 0) {
                this.setState({
                    list: data.data,//严重程度
                })
            }
        })
    }
    render() {
        const { oneList, oneArray, twoList, twoArray, threeList, threeArray, fourList, fourArry, list} = this.state
        console.log(oneList);
        console.log(twoList);
        console.log(threeList);
        console.log(fourList);
        return (
            <div className="boxCon">
                {/* 柱状趋势图 */}
                <div className="pagetitle">现场检查</div>
				{
                    oneList.length > 0 ? 
                        <NormalBarChart  chartTitle={'本年开单数'} chartId={'storeSizeChart'}
                       yxox={oneArray} oneList={oneList}/> : null
				} 

                {/* 柱状图 */}
                {
                    fourList.length > 0 ?
                        <NormalBarChart3 chartTitle={'严重程度'} chartId={'storeSizeChart3'}
                            yxox={fourArry} oneList={fourList} /> : null
                }

                {/* 柱状趋势图 */}
                {
                    twoList.length > 0 ?
                        <NormalBarChart1  chartTitle={'问题点分类'} chartId={'storeSizeChart1'}
                            yxox={twoArray} oneList={twoList} /> : null
                }
                {/* 柱状图 整改情况*/}
				{
                    threeList.length > 0 ?
                        <NormalBarChart2  chartTitle={'整改情况'} chartId={'storeSizeChart2'}
                        yxox={threeArray} oneList={threeList}/>: null
				} 
                {/* <Button className="btn" type="primary">下载图表</Button> */}

                <div className="nametitle">逾期未整改名单</div>
                <div className='history-table-wrap'>
                    <div className="table">
                        <div className="tr">
                            <div className="th th1">序号</div>
                            <div className="th th2">店铺名称</div>
                            <div className="th th3">企业名称</div>
                            <div className="th th4">数量</div>
                            <div className="th th5">逾期时间</div>
                        </div>
                        {
                            list.length == 0 ?
                                <div className="nodata" >
                                    暂无数据
                                </div> :
                                list.map((item, index) => {
                                    return (
                                        <div className="tr">
                                            <div className="td td1">{item.id}</div>
                                            <div className="td td2">{item.company.companyOldName}</div>
                                            <div className="td td3">{item.companyName}</div>
                                            <div className="td td4">1</div>
                                            <div className="td td5">{item.checkTime}</div>
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div> 
            </div>
        )
    }
}


