import React, { useEffect } from 'react';
import * as echarts from "echarts";
import '../../index.css';

const Index = (props) => {
    const operationObj = {};
    operationObj[props.chartId + 'Index'] = 0;
    operationObj[props.chartId + 'Interval'] = undefined;
    operationObj[props.chartId + 'Chart'] = undefined;

    useEffect(() => {
        return componentWillUnmount;
    }, []);

    function componentWillUnmount() {
        
    }
    return (
        <div className="normal-box p20">
            <div className="zhenggai" style={{ width: '100%', height: '100px' }}>
                <div className="first">{props.chartTitle}</div>
                <div className="two">
                    <div className="two1">
                        <div className="two2">{props.oneList[0]}</div>
                        <div className="two3">整改</div>
                    </div>

                    <div className="two1">
                        <div className="two2">{props.oneList[1]}</div>
                        <div className="two3">未整改</div>
                    </div>

                    <div className="two1">
                        <div className="two2">{props.oneList[2]}</div>
                        <div className="two3">审批驳回</div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Index;
