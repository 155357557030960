import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List, InputItem, DatePicker, Toast, Picker, Button } from 'antd-mobile';
import { Radio, Form } from 'antd';
import ReactLoading from 'react-loading';
import { getStreetList, emissons } from '../../api/wx';
import './index.css';
const Item = List.Item;
const Brief = Item.Brief;

const Emissions = (props) => {
    const [feiUniteList, setfeiUniteList] = useState([{ "label": "上海环欣环保科技有限公司", "value": "上海环欣环保科技有限公司" }, { "label": "上海中器环保科技有限公司", "value": "上海中器环保科技有限公司" }]);
    const [canUniteList, setcanUniteList] = useState([{ "label": "上海净达环境卫⽣发展有限公司", "value": "上海净达环境卫⽣发展有限公司" }]);
    const [seasons, setseasons] = useState([]);
    const [streetName, setstreetName] = useState('');//街镇园区名字
    const [companyId, setcompanyId] = useState(322);//关联公司id
    const [companyName, setcompanyName] = useState('');//企业名称
    const [companyOldName, setcompanyOldName] = useState('');//店铺名字
    const [companyCode, setcompanyCode] = useState('');//统一社会信用代码
    const [companyHead, setcompanyHead] = useState('');//负责人
    const [companyNumber, setcompanyNumber] = useState('');//手机号码
    const [companyBusAds, setcompanyBusAds] = useState('');//经营地址
    const [isYuWuPai, setisYuWuPai] = useState('');//是否存在雨污混排
    const [yuWuPaiReason, setyuWuPaiReason] = useState('');//雨污混排原因说明
    const [isYuWuJie, setisYuWuJie] = useState('');//是否存在雨污混接
    const [yuWuJieReason, setyuWuJieReason] = useState('');//雨污混接原因说明
    const [isDra, setisDra] = useState('');//有无排水许可证
    const [draNumber, setdraNumber] = useState('');//排水许可证编号
    const [draReason, setdraReason] = useState('');//无排水许可证的原因
    const [isPaiYan, setisPaiYan] = useState('');//是否存在违法排烟
    const [isFeiUnite, setisFeiUnite] = useState('');//是否有废油脂收运单位
    const [feiReason, setfeiReason] = useState('');//没有废油脂收运单位的原因
    const [feiUnite, setfeiUnite] = useState('');//废油脂收运单位名称
    const [isCanUnite, setisCanUnite] = useState('');//是否有餐厨垃圾收运单位
    const [canReason, setcanReason] = useState('');//没有餐厨垃圾收运单位的原因
    const [canUnite, setcanUnite] = useState('');//餐厨垃圾收运单位名称
    const [isCer, setisCer] = useState('');//是否有食品许可证
    const [cerNumber, setcerNumber] = useState('');//食品许可证编号
    const [cerReason, setcerReason] = useState('');//未取得食品许可证原因
    const [isOther, setisOther] = useState('');//是否存在其他违规行为
    const [otherReason, setotherReason] = useState('');//存在其他违规行为具体说明
    const [checkTime, setcheckTime] = useState('');//检查日期
    const [rectTime, setrectTime] = useState('');//整改日期
    const [btndisable, setbtndisable] = useState(false);
    const [loading, setLoding] = useState(true);

    //网络请求
    useEffect(() => {
        if (localStorage.getItem("adminToken") == null) { //没有adminToken跳到登录页面
            setLoding(false);
            props.history.push('/adminlogin');//跳到登录页
        } else {
            setLoding(false);
            getStreetList().then((data) => {
                let ary = data.data;
                let streetList = [];
                for (let i = 0; i < ary.length; i++) {
                    let obj = {
                        label: ary[i].streeName,
                        value: ary[i].streeName,
                    }
                    streetList.push(obj);
                }
                setseasons(streetList);
            })
        }
        let dat = sessionStorage.getItem("company");
        console.log('===', dat);
        if (dat) {
            console.log(JSON.parse(dat)['companyName']);
            setstreetName(sessionStorage.getItem("streetName"))
            setcompanyName(JSON.parse(dat)['companyName'])
            setcompanyOldName(JSON.parse(dat)['companyOldName'])
            setcompanyCode(JSON.parse(dat)['companyCode'])
            setcompanyHead(JSON.parse(dat)['companyHead'])
            setcompanyNumber(JSON.parse(dat)['companyNumber'])
            setcompanyBusAds(JSON.parse(dat)['companyBusAds'])
        }
    }, [])


    //雨污混排-----
    const isYuWuPaiChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisYuWuPai(flag)
        if (flag == '否') {
            setyuWuPaiReason('')
        }
    }
    const yuWuPaiReasonChange = (e) => {
        console.log('===', e);
        setyuWuPaiReason(e)
    }


    //雨污混接
    const isYuWuJieChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisYuWuJie(flag)
        if (flag == '否') {
            setyuWuJieReason('')
        }
    }
    const yuWuJieReasonChange = (e) => {
        console.log('===', e);
        setyuWuJieReason(e)
    }

    //有无排水许可
    const isDraChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisDra(flag)
        if (flag == '有') {
            setdraReason('')
        }
    }
    const draNumberChange = (e) => {
        console.log('===', e);
        setdraNumber(e)
    }
    const draReasonChange = (e) => {
        console.log('===', e);
        setdraReason(e)
    }

    //是否违法排烟
    const isPaiYanChange = (e) => {
        console.log('radio checked', e.target.value);
        setisPaiYan(e.target.value)
    }

    //废油脂收运单位
    const isFeiUniteChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisFeiUnite(flag)
        if (flag == '有') {
            setfeiReason('')
        }
    }
    const feiReasonChange = (e) => {
        console.log('===', e);
        setfeiReason(e)
    }
    const feiUniteChange = (e) => {
        console.log('===', e[0]);
        setfeiUnite(e[0])
    }

    //餐厨垃圾收运单位
    const isCanUniteChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisCanUnite(flag)
        if (flag == '有') {
            setcanReason('')
        }
    }
    const canReasonChange = (e) => {
        console.log('===', e);
        setcanReason(e)
    }
    const canUniteChange = (e) => {
        console.log('===', e[0]);
        setcanUnite(e[0])
    }

    //是否取得食品许可证
    const isCerChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisCer(flag)
        if (flag == '是') {
            setcerReason('')
        }
    }
    const cerReasonChange = (e) => {
        console.log('radio checked', e);
        setcerReason(e)
    }
    const cerNumberChange = (e) => {
        console.log('radio checked', e);
        setcerNumber(e)
    }

    //其他违规行为
    const isOtherChange = (e) => {
        let flag = e.target.value;
        console.log('flag==', flag);
        setisOther(flag)
        if (flag == '否') {
            setotherReason('')
        }
    }
    const otherReasonChange = (e) => {
        console.log('radio checked', e);
        setotherReason(e)
    }

    const Sumit = () => {
        if (!streetName) {
            Toast.info('街镇园区不能为空', 1);
            return;
        }
        if (!companyName) {
            Toast.info('公司名称不能为空', 1);
            return;
        }
        if (!checkTime || !rectTime) {
            Toast.info('检查整改时间不能为空', 1);
            return;
        }
        let obj = {
            streetName,
            companyId,
            companyName,
            companyOldName,
            companyCode,
            companyHead,
            companyNumber,
            companyBusAds,
            isYuWuPai,
            yuWuPaiReason,
            isYuWuJie,
            yuWuJieReason,
            isDra,
            draNumber,
            draReason,
            isPaiYan,
            isFeiUnite,
            feiReason,
            feiUnite,
            isCanUnite,
            canReason,
            canUnite,
            isCer,
            cerNumber,
            cerReason,
            isOther,
            otherReason,
            checkTime,
            rectTime,
            isDelete: 0
        }
        console.log(obj);
        //post请求接口数据
        emissons(obj).then((data) => {
            Toast.info('数据已提交', 2);
            setbtndisable(true)
        })
    }
    return (
            <div>
                {
                    loading ?
                    <ReactLoading className="loading" type="spinningBubbles" color="#171717" width={100} height={100} />
                    :
                    <div>
                        <div className="headertitle">违法排污</div>
                        <div className='headerlsit'>
                            <List className="my-list">
                                <Picker data={seasons} extra={streetName} cols={1} className="forss"
                                    onOk={e => { console.log('ok', e); setstreetName(e[0]); sessionStorage.setItem('streetName', e[0]); }}>
                                    <Item arrow="horizontal" extra={'Single'}>街镇园区</Item>
                                </Picker>
                                <Item arrow="horizontal" onClick={() => { props.history.push('/emissionscompany') }} extra={companyOldName}>店铺名称</Item>
                                <Item extra={companyName}>公司名称</Item>

                                <Item extra={companyCode}>统一社会信用代码</Item>
                                <Item extra={companyHead}>联系人</Item>
                                <Item extra={companyNumber}>移动电话</Item>
                                <Item extra={companyBusAds}>详细地址</Item>
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>是否存在雨污混排<Brief>
                                    <Radio.Group className='radiorow' onChange={isYuWuPaiChange} >
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isYuWuPai == '是' ?
                                        <InputItem className='yysm' placeholder="请输入" value={yuWuPaiReason} onChange={yuWuPaiReasonChange}>原因说明*</InputItem>
                                        :
                                        ''
                                }

                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>是否存在雨污混接<Brief>
                                    <Radio.Group className='radiorow' onChange={isYuWuJieChange}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isYuWuJie == '是' ?
                                        <InputItem className='yysm' placeholder="请输入" value={yuWuJieReason} onChange={yuWuJieReasonChange}>原因说明*</InputItem>
                                        :
                                        ''
                                }
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>有无排水许可证<Brief>
                                    <Radio.Group className='radiorow' onChange={isDraChange}>
                                        <Radio value={"有"}>有</Radio>
                                        <Radio value={"无"}>无</Radio>
                                        <Radio value={"共用"}>共用</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isDra == '有' ?
                                        <InputItem className='yysm' placeholder="请输入" value={draNumber} onChange={draNumberChange}>许可证编号</InputItem>
                                        :
                                        <InputItem className='yysm' placeholder="请输入" value={draReason} onChange={draReasonChange}>原因说明*</InputItem>
                                }
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>是否存在违法排放油烟<Brief>
                                    <Radio.Group className='radiorow' onChange={isPaiYanChange}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                        <Radio value={"不涉及"}>不涉及</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>废油脂收运单位<Brief>
                                    <Radio.Group className='radiorow' onChange={isFeiUniteChange}>
                                        <Radio value={"有"}>有</Radio>
                                        <Radio value={"无"}>无</Radio>
                                        <Radio value={"过期"}>过期</Radio>
                                        <Radio value={"不适用"}>不适用</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isFeiUnite == '有' ?
                                        // <InputItem className='yysm' placeholder="请输入" value={feiUnite} onChange={feiUniteChange}>收运单位</InputItem>
                                        <Picker data={feiUniteList} extra={feiUnite} cols={1} className="forss"
                                            onOk={feiUniteChange}>
                                            <Item arrow="horizontal" extra={''}>收运单位</Item>
                                        </Picker>
                                        :
                                        <InputItem className='yysm' placeholder="请输入" value={feiReason} onChange={feiReasonChange}>原因说明*</InputItem>
                                }

                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>餐厨垃圾收运单位<Brief>
                                    <Radio.Group className='radiorow' onChange={isCanUniteChange}>
                                        <Radio value={"有"}>有</Radio>
                                        <Radio value={"无"}>无</Radio>
                                        <Radio value={"过期"}>过期</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isCanUnite == '有' ?
                                        // <InputItem className='yysm' placeholder="请输入" value={canUnite} onChange={canUniteChange}>收运单位</InputItem>
                                        <Picker data={canUniteList} extra={canUnite} cols={1} className="forss"
                                            onOk={canUniteChange}>
                                            <Item arrow="horizontal" extra={''}>收运单位</Item>
                                        </Picker>
                                        :
                                        <InputItem className='yysm' placeholder="请输入" value={canReason} onChange={canReasonChange}>原因说明*</InputItem>

                                }
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>是否取得食品经营许可证<Brief>
                                    <Radio.Group className='radiorow' onChange={isCerChange}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isCer == '是' ?
                                        '' :
                                        <InputItem className='yysm' placeholder="请输入" value={cerReason} onChange={cerReasonChange}>原因说明*</InputItem>
                                }
                            </List>
                        </div>
                        <div className='headertwo'>
                            <List className="my-list">
                                <Item onClick={() => { }}>是否存在其他违规行为<Brief>
                                    <Radio.Group className='radiorow' onChange={isOtherChange}>
                                        <Radio value={"是"}>是</Radio>
                                        <Radio value={"否"}>否</Radio>
                                    </Radio.Group>
                                </Brief></Item>
                                {
                                    isOther == '是' ?
                                        <InputItem className='yysm' placeholder="请输入" value={otherReason} onChange={otherReasonChange}>原因说明*</InputItem>
                                        :
                                        ''
                                }
                            </List>
                        </div>
                        <div className='headerlsit'>
                            <List className="my-list">
                                <DatePicker
                                    mode="date"
                                    title="选择检查时间"
                                    extra={checkTime}
                                    onChange={e => {
                                        let year = e.getFullYear();
                                        let month = e.getMonth() + 1;
                                        let day = e.getDate()
                                        setcheckTime(year + '-' + month + '-' + day)
                                    }}
                                >
                                    <List.Item arrow="horizontal">检查时间</List.Item>
                                </DatePicker>
                                <DatePicker
                                    mode="date"
                                    title="选择整改时间"
                                    extra={rectTime}
                                    onChange={e => {
                                        let year = e.getFullYear();
                                        let month = e.getMonth() + 1;
                                        let day = e.getDate()
                                        setrectTime(year + '-' + month + '-' + day)
                                    }}
                                >
                                    <List.Item arrow="horizontal">整改时间</List.Item>
                                </DatePicker>
                            </List>
                        </div>
                        <div>
                            <List>
                                <List.Item>
                                    <Button style={{ "fontSize": "15px" }} type="primary" disabled={btndisable} onClick={Sumit}>提交</Button>
                                </List.Item>
                            </List>
                        </div>
                    </div>
                }
            </div>

    )
}

export default Emissions;