import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { getWxCompanyInfo, weifa, youYanData, YouLiangCha, xianchang, geYouZhiData, youShuiData,MiddleNumStr, canChuData, getWxCU, fyzData, getFeiYouZhi } from '../../api/wx';
import NormalBarChart from './huanxin';
import { List, Button, Image, ListView, div, SearchBar, Icon, Pagination, Badge, Toast, Tabs, Picker } from 'antd';
import ReactLoading from 'react-loading';
import './index.css';


const Home = (props) => {
    //餐厨垃圾
    const ItemClick = () => {
        props.history.push('/canchu');
    }
    //废油脂
    const feiyouzhi = () => {
        props.history.push('/feiyouzhi');
    }
    //隔油池
    const geyouchi = () => {
        props.history.push('/geyou');
    }
	//现场检查
    const xianchangclick =() => {
        props.history.push('/xianchang');
    }
    
    //违法排污
    const weifaclick =()=>{
        props.history.push('/weifapaiwu');
    }
    //店铺总数 评分
    const shipinclick =() => {
        props.history.push('/shipin');
    }
    //油水分离器
    const youshuifeng =() =>{
        props.history.push('/youshui');
    }

    //油烟净化器
    const youyanjinhua = () => {
        props.history.push('/youyan');
    }

	const [cancu,setCancu] = useState([]); //餐厨
    const [fyz,setFyz] = useState([]);   //废油池 
	const [cancutub,setCancutub] = useState(false); //餐厨表格
	const [fyztub,setFyztub] = useState(false);  //废油池表格
    //--------------------------------------------------------------------------------------------------------
    const [geyou, setGeyou] = useState([]); //餐厨表格
    const [geyoutub, setgeyoutub] = useState(false);  //废油池表格

    const [youshui, setYoushui] = useState([]); //餐厨表格
    const [youshuitub, setYoushuitub] = useState(false);  //废油池表格

    const [youyan, setYouyan] = useState([]); //餐厨表格
    const [youyantub, setYouyantub] = useState(false);  //废油池表格
    //--------------------------------------------------------------------------------------------------------
    const [sum, setsum] = useState('');  //店铺统计总数

    const [You, setYou] = useState('');//优秀
    const [Liang, setLiang] = useState('');//良好
    const [Cha, setCha] = useState('');//差

    const [check, setcheck] = useState('');  //现场检查表格数据

    const [emission, setemission] = useState('');  //违法排污
    const [checktub, setchecktub] = useState(false);
	
    const [loading, setLoding] = useState(true);

    useEffect(() => {
        if (localStorage.getItem("adminToken") == null) { //没有adminToken跳到登录页面
            setLoding(false);
                props.history.push('/adminlogin');//跳到登录页
        } else {
            setLoding(false);
            //请求接口数据---餐厨垃圾
            // canChuData().then(data =>{
			// 	let dat=data.data;
			// 	let num1=dat.lastDay*100;
			// 	num1 = num1.toFixed(2); 
			// 	if(dat.lastDay>=0){
			// 		dat.lastDay="+"+num1;
			// 	}else{
			// 		dat.lastDay=num1;
			// 	}
				
				
			// 	let num2=dat.lastMon*100;
			// 	num2 = num2.toFixed(2); 
			// 	if(dat.lastMon>=0){
			// 		dat.lastMon="+"+num2;
			// 	}else{
			// 		dat.lastMon=num2;
			// 	}
				
			// 	let num3=dat.lastYear*100;
			// 	num3 = num3.toFixed(2); 
			// 	if(dat.lastYear>=0){
			// 		dat.lastYear="+"+num3;
			// 	}else{
			// 		dat.lastYear=num3;
			// 	}
			// 	setCancu(dat);
			// 	setCancutub(true);
            // });
            // //废油脂
			// fyzData().then(data =>{
			//     let dat=data.data;
			// 	let num1=dat.lastDay*100;
			// 	num1 = num1.toFixed(2); 
			// 	if(dat.lastDay>=0){
			// 		dat.lastDay="+"+num1;
			// 	}else{
			// 		dat.lastDay=num1;
			// 	}
				
				
			// 	let num2=dat.lastMon*100;
			// 	num2 = num2.toFixed(2); 
			// 	if(dat.lastMon>=0){
			// 		dat.lastMon="+"+num2;
			// 	}else{
			// 		dat.lastMon=num2;
			// 	}
				
			// 	let num3=dat.lastYear*100;
			// 	num3 = num3.toFixed(2); 
			// 	if(dat.lastYear>=0){
			// 		dat.lastYear="+"+num3;
			// 	}else{
			// 		dat.lastYear=num3;
			// 	}
			// 	setFyz(dat);
			// 	setFyztub(true);
			// });
            // //------------------------------------------------------------------------------------
            // //隔油池统计
            // geYouZhiData().then(data => {
            //     let dat = data.data;
            //     let num1 = dat.lastDay * 100;
            //     num1 = num1.toFixed(2);
            //     if (dat.lastDay >= 0) {
            //         dat.lastDay = "+" + num1;
            //     } else {
            //         dat.lastDay = num1;
            //     }


            //     let num2 = dat.lastMon * 100;
            //     num2 = num2.toFixed(2);
            //     if (dat.lastMon >= 0) {
            //         dat.lastMon = "+" + num2;
            //     } else {
            //         dat.lastMon = num2;
            //     }

            //     let num3 = dat.lastYear * 100;
            //     num3 = num3.toFixed(2);
            //     if (dat.lastYear >= 0) {
            //         dat.lastYear = "+" + num3;
            //     } else {
            //         dat.lastYear = num3;
            //     }
            //     setGeyou(dat);
            //     setgeyoutub(true);
            // })
            // //油水分离器统计
            // youShuiData().then(data =>{
            //     let dat = data.data;
            //     let num1 = dat.lastDay * 100;
            //     num1 = num1.toFixed(2);
            //     if (dat.lastDay >= 0) {
            //         dat.lastDay = "+" + num1;
            //     } else {
            //         dat.lastDay = num1;
            //     }


            //     let num2 = dat.lastMon * 100;
            //     num2 = num2.toFixed(2);
            //     if (dat.lastMon >= 0) {
            //         dat.lastMon = "+" + num2;
            //     } else {
            //         dat.lastMon = num2;
            //     }

            //     let num3 = dat.lastYear * 100;
            //     num3 = num3.toFixed(2);
            //     if (dat.lastYear >= 0) {
            //         dat.lastYear = "+" + num3;
            //     } else {
            //         dat.lastYear = num3;
            //     }
            //     setYoushui(dat);
            //     setYoushuitub(true);
            // })
            // //油烟净化器
            // youYanData().then(data =>{
            //     let dat = data.data;
            //     let num1 = dat.lastDay * 100;
            //     num1 = num1.toFixed(2);
            //     if (dat.lastDay >= 0) {
            //         dat.lastDay = "+" + num1;
            //     } else {
            //         dat.lastDay = num1;
            //     }


            //     let num2 = dat.lastMon * 100;
            //     num2 = num2.toFixed(2);
            //     if (dat.lastMon >= 0) {
            //         dat.lastMon = "+" + num2;
            //     } else {
            //         dat.lastMon = num2;
            //     }

            //     let num3 = dat.lastYear * 100;
            //     num3 = num3.toFixed(2);
            //     if (dat.lastYear >= 0) {
            //         dat.lastYear = "+" + num3;
            //     } else {
            //         dat.lastYear = num3;
            //     }
            //     setYouyan(dat);
            //     setYouyantub(true);
            // })
            // //------------------------------------------------------------------------------------
            // //统计店铺总数
            // MiddleNumStr().then(data =>{
            //     console.log(data);
            //     setsum(data.data)
            // })
            // //优良差数据接口
            // YouLiangCha().then(data => {
            //     console.log(data);
            //     if(data.errno == 0){
            //         setYou(data.data[0])
            //         setCha(data.data[1])
            //         setLiang(data.data[2])
            //     }
                
            // })
            // //现场检查
            // xianchang().then(data => {
            //     setcheck(data.data)
            //     setchecktub(true)
            // })
            // //违法排污接口数据
            // weifa().then(data => {
            //     setemission(data.data)
            // })
        }
    }, [])
    return (
        <div>
            {
                loading ? 
                    <ReactLoading className="loading" type="spinningBubbles" color="#171717" width={100} height={100} />
                :
                    <div className="content">
                        <div className="cahchu" onClick={() => ItemClick()}>
                            <div className="top">
                                <div className="left">
                                    <div className="tv1">
                                        餐厨垃圾
                                    </div>
                                    <div className="suble">
                                        <div className="sum">
                                            <div className="zong">总注册数</div>
                                            <div className="znum">237</div>
                                        </div>
                                        <div className="sum">
                                            <div className="zong">今日登记店数</div>
                                            <div className="znum">30</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        cancutub ?
                                            <NormalBarChart chartId={'storeSizeChart1'} carslist1={45} carslist2={87} /> : null
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="btmone">
                                    <div className="leiji">今日累计:200吨</div>
                                    <div className="leiji">较上日60%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本月累计:1200吨</div>
                                    <div className="leiji">较上月43.5%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本年度累计:15000吨</div>
                                    <div className="leiji">较上年12.56%</div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu2" onClick={() => feiyouzhi()}>
                            <div className="top">
                                <div className="left">
                                    <div className="tv1">
                                        废油脂
                                    </div>
                                    <div className="suble">
                                        <div className="sum">
                                            <div className="zong">总注册数</div>
                                            <div className="znum">237</div>
                                        </div>
                                        <div className="sum">
                                            <div className="zong">今日登记店数</div>
                                            <div className="znum">45</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        fyztub ?
                                            <NormalBarChart chartId={'storeSizeChart2'} carslist1={35} carslist2={80} /> : null
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="btmone">
                                    <div className="leiji">今日累计:30吨</div>
                                    <div className="leiji">较上日12.2%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本月累计:1200吨</div>
                                    <div className="leiji">较上月34.56%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本年累计:23000吨</div>
                                    <div className="leiji">较上年98.45%</div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu3" onClick={() => geyouchi()}>
                            <div className="top">
                                <div className="left">
                                    <div className="tv1">
                                        隔油池
                                    </div>
                                    <div className="suble">
                                        <div className="sum">
                                            <div className="zong">总注册数</div>
                                            <div className="znum">237</div>
                                        </div>
                                        <div className="sum">
                                            <div className="zong">今日登记店数</div>
                                            <div className="znum">123</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        geyoutub ?
                                            <NormalBarChart chartId={'storeSizeChart3'} carslist1={25} carslist2={67} /> : null
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="btmone">
                                    <div className="leiji">今日累计:5次</div>
                                    <div className="leiji">较上日45.6%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本月累计:342次</div>
                                    <div className="leiji">较上月1.23%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本年累计:5095次</div>
                                    <div className="leiji">较上年12.34%</div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu4" onClick={() => youshuifeng()}>
                            <div className="top">
                                <div className="left">
                                    <div className="tv1">
                                        油水分离器
                                    </div>
                                    <div className="suble">
                                        <div className="sum">
                                            <div className="zong">总注册数</div>
                                            <div className="znum">237</div>
                                        </div>
                                        <div className="sum">
                                            <div className="zong">今日上报店铺</div>
                                            <div className="znum">12</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        youshuitub ?
                                            <NormalBarChart chartId={'storeSizeChart4'} carslist1={15} carslist2={47} /> : null
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="btmone">
                                    <div className="leiji">今日累计:232次</div>
                                    <div className="leiji">较上日34.3%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本月累计:500次</div>
                                    <div className="leiji">较上月32.1%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本年累计:2399次</div>
                                    <div className="leiji">较上年12.2%</div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu5" onClick={() => youyanjinhua()}>
                            <div className="top">
                                <div className="left">
                                    <div className="tv1">
                                        油烟净化器
                                    </div>
                                    <div className="suble">
                                        <div className="sum">
                                            <div className="zong">总注册数</div>
                                            <div className="znum">237</div>
                                        </div>
                                        <div className="sum">
                                            <div className="zong">今日登记店数</div>
                                            <div className="znum">12</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="right">
                                    {
                                        youyantub ?
                                            <NormalBarChart chartId={'storeSizeChart5'} carslist1={55} carslist2={87} /> : null
                                    }
                                </div>
                            </div>
                            <div className="bottom">
                                <div className="btmone">
                                    <div className="leiji">今日累计:34次</div>
                                    <div className="leiji">较上日34.3%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本月累计:122次</div>
                                    <div className="leiji">较上月{youyan.lastMon}%</div>
                                </div>
                                <div className="btmone">
                                    <div className="leiji">本年累计:1232次</div>
                                    <div className="leiji">较上年11.21%</div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu6" onClick={() => shipinclick()}>
                            <div className="top">
                                <div className="tv1">
                                    食品经营许可证
                                </div>
                                <div className="suble6">
                                    餐饮单位：{sum[0]}
                                </div>
                            </div>
                            <div className="bottom6">
                                <div className="bt1">
                                    <div className="btmone6">
                                        <div className="leiji6">热加工:</div>
                                        <div className="leiji6">233</div>
                                    </div>
                                    <div className="btmone6">
                                        <div className="leiji6">糕点类:</div>
                                        <div className="leiji6">13</div>
                                    </div>
                                    <div className="btmone6">
                                        <div className="leiji6">自制饮料:</div>
                                        <div className="leiji6">22</div>
                                    </div>
                                    <div className="btmone6">
                                        <div className="leiji6">其他 :</div>
                                        <div className="leiji6">2</div>
                                    </div>
                                </div>
                                <div className='bt2'>
                                    <div className="manyi">
                                        <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/8F1518A4979912F9BF2B9788F9EEE7C0.png"></Image>
                                        <div className="youh">优</div>
                                        <div className="counth">{You}</div>
                                    </div>
                                    <div className="manyi">
                                        <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/AF3767A23CF863998D5240AE53F639DE.png"></Image>
                                        <div className="youh">良</div>
                                        <div className="counth">{Liang}</div>
                                    </div>
                                    <div className="manyi">
                                        <Image className="myImg" preview={false} src="https://yfadmin.chuanfu.ltd/profile/D6FD858FE17C5DBA7FC16957BAEAD350.png"></Image>
                                        <div className="youh">差</div>
                                        <div className="counth">{Cha}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cahchu7" onClick={() => xianchangclick()}>
                            <div className="left7">
                                <div className="tv17">
                                    现场检查
                                </div>
                                <div className="sum7">
                                    <div className="zong">总数：</div>
                                    <div className="znum">390</div>
                                </div>
                            </div>
                            <div className="btm7">
                                <div className="bottom7">
                                    <div className="btmone">
                                        <div className="leiji">本年度开单数:33次</div>
                                    </div>
                                    <div className="btmone">
                                        <div className="leiji">本月开单数:43次</div>
                                    </div>
                                    <div className="btmone">
                                        <div className="leiji">今日开单数:2次</div>
                                    </div>
                                </div>
                                <div className="right7">
                                    {
                                        check ?
                                            <NormalBarChart chartId={'storeSizeChart6'} carslist1={check['statusOver']} carslist2={check['status']} /> : null
                                    }
                                </div>
                            </div>
                        </div>

                        {/* 新增违法排污 */}
                        <div className="cahchu8" onClick={() => weifaclick()}>
                            <div className="left7">
                                <div className="tv17">
                                    违法排污
                                </div>
                                <div className="sum7">
                                    <div className="zong">总数：</div>
                                    <div className="znum">200</div>
                                </div>
                            </div>
                            <div className="btm7">
                                <div className="bottom7">
                                    <div className="btmone">
                                        <div className="leiji">本年度开单数:123次</div>
                                    </div>
                                    <div className="btmone">
                                        <div className="leiji">本月开单数:22次</div>
                                    </div>
                                    <div className="btmone">
                                        <div className="leiji">今日开单数:3次</div>
                                    </div>
                                </div>
                                <div className="right7">
                                    {
                                        emission ?
                                            <NormalBarChart chartId={'storeSizeChart7'} carslist1={emission['statusOver']} carslist2={emission['status']} /> : null
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
            }
        </div>
        
       
    )
}

export default Home;
