import React, { useEffect } from 'react';
// import { connect } from 'umi';
import * as echarts from "echarts";
import './index.css';

const Index = (props) => {//props.chartId
	var baifbi="3.25%"
	baifbi=("+"+(props.carslist1/props.carslist2*100).toFixed(2))+"%";
    const operationObj = {};
    let { dispatch, LeftOneList } = props;
    useEffect(() => {
        return componentWillUnmount;
    }, []);

    function componentWillUnmount() {
    }

    function getChartOption() {
        return {
            title: {
            },
            tooltip: {
                trigger: 'item',
            },
            legend: {
                show:false,
            },
            series: [
                {
                    name: '餐厨垃圾',
                    type: 'pie',
                    radius: ['70%', '90%'],
                    data: [
                        {
                            value: 45, name: '', itemStyle: {
                                normal: {
                                    color: 'rgb(50,197,233)',
                                }
                            }},
                        {
                            value: 90, name: '', itemStyle: {
                                normal: {
                                    color: '#ffffff',
                                }
                            }},
                    ],
                    label: { 　　　　　　　　　　//去除饼图的指示折线label
                        normal: {
                            show: true,
                            position: 'center',
                            formatter: baifbi,
                            textStyle:{
                                fontSize:15,
                                color:"#ffffff"
                            }
                        },
                    },
                }
            ],
        }
    }

    useEffect(() => {
        operationObj['Chart'] = echarts.init(document.getElementById(props.chartId));
        operationObj['Chart'].setOption(getChartOption());
    }, [])

    return (
        <div className="normal-box p20">
            <div id={props.chartId} style={{ width: '100%', height: '100%' }}>
            </div>
        </div>
    )
}
export default Index;
