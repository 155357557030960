import React, { useEffect } from 'react';
import * as echarts from "echarts";

const Index = (props) => {
    const operationObj = {};
    operationObj[props.chartId + 'Index'] = 0;
    operationObj[props.chartId + 'Interval'] = undefined;
    operationObj[props.chartId + 'Chart'] = undefined;

    useEffect(() => {
        if (operationObj[props.chartId + 'Chart'] != null && operationObj[props.chartId + 'Chart'] != "" && operationObj[props.chartId + 'Chart'] != undefined) {
            operationObj[props.chartId + 'Chart'].dispose();
        }

        operationObj[props.chartId + 'Chart'] = echarts.init(document.getElementById(props.chartId));
        operationObj[props.chartId + 'Chart'].setOption(getChartOption());
        return componentWillUnmount;
    }, []);

    function componentWillUnmount() {
        if (operationObj[props.chartId + 'Interval']) {
            clearInterval(operationObj[props.chartId + 'Interval']);
        }
        if (operationObj[props.chartId + 'Chart'] && operationObj[props.chartId + 'Chart'].id) {
            operationObj[props.chartId + 'Chart'].dispose();
        }
    }

    function getChartOption() {
		console.log("----------接受数据------------",props.carslist);
        return {
            title:{
                show: true,
                text: props.chartTitle,
                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#000"
                },
            },
            tooltip: {
                trigger: 'item',
                formatter: '{a} <br/>{b}: {c} ({d}%)'
            },
            legend: {
                orient: 'vertical',
                left: 'right',
                data: ['严重', '较严重', '一般']
            },
            series: [
                {
                    name: '访问来源',
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    labelLine: {
                        show: true
                    },
                    data: [
                        { value: props.oneList[0], name: '严重' },
                        { value: props.oneList[1], name: '较严重' },
                        { value: props.oneList[2], name: '一般' },
                    ]
                }
            ]
        }
    }

    return (
        <div className="normal-box p20">
            <div id={props.chartId} style={{ width: '95%', height: '150px' ,margin:'10xp auto'}}>
            </div>
        </div>
    )
}
export default Index;
