import request from "../util/request";

var URL = "https://yfserver.chuanfu.ltd";//http://localhost:3002  https://yfserver.chuanfu.ltd
export function weChatLogin(code) {
  return request({
    url: URL+"/api/wx/code?code=" + code,
    method: "get"
  });
}

//获取隔油池信息getGeYouChiData
export function getCompanyName(companyName) {
  return request({
    url: URL + '/api/wx/getCompanyNameList?companyName=' + companyName,
    method: 'get'
  })
}


//获取隔油池信息getGeYouChiData
export function getGeYouChiData(type, reg, area) {
  return request({
    url: URL + '/api/wx/getGeYouChiData?type=' + type + '&reg=' + reg + '&area=' + area,
    method: 'get'
  })
}

//获取油水分离器信息getYouShui
export function getYouShuiListData(type, reg, area) {
  return request({
    url: URL + '/api/wx/getYouShuiListData?type=' + type + '&reg=' + reg + '&area=' + area,
    method: 'get'
  })
}


//获取公司信息
export function getCompanyInfo(id,openid) {  
  return request({
    url: URL+'/api/wx/getCompanyInfo?id='+id+'&openid='+openid,
    method: 'get'
  })
}
//解除绑定
export function getInfoWxOut(openid) {
  console.log(openid); 
  return request({
    url: URL+'/api/wx/getWxCuOut?openid='+openid,
    method: 'get'
  })
}
// 获取用户详细信息
export function getWxInfo() {
  return request({
    url: URL+'/api/wx/getWxInfo',
    method: 'get'
  })
}
//管理员登陆
export function adminLogin(data){
  return request({
    url: URL + '/api/user/login',
    method: 'post',
    data
  })
};
//管理员获取餐厨垃圾的数据
export function canChuData() {
  return request({
    url: URL + '/api/wx/getHome',
    method: 'get',
  })
};
//管理员获取废油脂的数据
export function fyzData() {
  return request({
    url: URL + '/api/wx/getHomeTwo',
    method: 'get',
  })
};

//管理员获取违法排污数据
export function weifa(){
  return request({
    url: URL + '/api/wx/weifa',
    method: 'get',
  })
}

//管理员获取现场检查数据
export function xianchang(){
  return request({
    url: URL + '/api/wx/getXianChang',
    method: 'get',
  })
}
//管理员获取数据
export function MiddleNumStr() {
  return request({
    url: URL + '/api/company/getMiddleNumStr',
    method: 'get',
  })
}
//油烟净化器统计总数
export function youYanData() {
  return request({
    url: URL + '/api/wx/getYouYanData',
    method: 'get',
  })
};
//油水分离器统计总数
export function youShuiData(){
  return request({
    url: URL + '/api/wx/getYouShuiData',
    method: 'get',
  })
}; 

//隔油池统计总数
export function geYouZhiData() {
  return request({
    url: URL + '/api/wx/getGeYouZhiData',
    method: 'get',
  })
};

//------------------------------------------------10/12----------------------------
export function getCheckOne(){
  return request({
    url: URL + '/api/wx/getCheckOne',
    method: 'get',
  })
};
export function getCheckTwo() {
  return request({
    url: URL + '/api/wx/getCheckTwo',
    method: 'get',
  })
};

export function YouLiangCha() {
  return request({
    url: URL + '/api/wx/getDianPinLun',
    method: 'get',
  })
};

export function getEmissionList() {
  return request({
    url: URL + '/api/check/getEmissionList?current=1&companyOldName=&streetName=&checkDateStart=&checkDateEnd=&size=100000',
    method: 'get',
  })
};

export function getCertificateDate() {
  return request({
    url: URL + '/api/company/getCertificateDate',
    method: 'get',
  })
};

export function getYearList() {
  return request({
    url: URL + '/api/company/getYearList',
    method: 'get',
  })
};













//根据用户输入统一社会信用代码的查询公司具体信息
export function getWxCompanyInfo(companyCode,companyNumber) {
  return request({
    url: URL+'/api/wx/getWxCompanyInfo?companyCode='+companyCode + '&companyNumber=' + companyNumber,
    method: 'get'
  })
}
//绑定
export function getWxCU(openid,unionid,companyId) {
  return request({
    url: URL + '/api/wx/getWxCU?openid=' + openid + '&unionid=' + unionid +'&companyId='+companyId,
    method: 'get'
  })
}
// 微信用户绑定公司
export function bindingCompany(data) {
  return request({
    url: URL+'/wx/bindingCompany',
    method: 'post',
    data
  })
}
//微信图片上出
export function wxupload(data) {  
  return request({
    url: URL+'/api/upload/wxuploadFile',
    method: 'post',
    data
  })
}
//微信图片删除接口
export function deletephone(data) {  
  return request({
    url: URL+'/api/upload/deleteFiles',
    method: 'post',
    data
  })
}
// 微信用户绑定公司
export function setSlMessage(data) {
  return request({
    url: URL+'/api/check/setSlMessage',
    method: 'post',
    data
  })
}

// 微信解绑
export function unbindCompany() {
  return request({
    url: URL+'/wx/unbindCompany',
    method: 'get'
  })
}

//查询订单信息
export function getCheckContent(companyId,type) {
  return request({
    url: URL+'/api/wx/getCheckContent?companyId='+companyId+'&type='+type,
    method: 'get'
  })
}

//获取街道列表
export function getStreetList() {
  return request({
    url: URL+'/api/wx/getStreetList',
    method: 'get'
  })
}

//违法排烟post数据

export function emissons(data) {
  return request({
    url: URL+'/api/wx/emissonsPost',
    method: 'post',
    data
  })
}
export function getCheckContentInfo(id) {
  return request({
    url: URL+'/api/wx/getCheckContentInfo?id='+id,
    method: 'get'
  })
}

export function getQuYu() {
  return request({
    url: URL+'/api/wx/getQuYu',
    method: 'get'
  })
} 
export function getYouYanListData(leixin,type, reg, area) {
  return request({
    url: URL + '/api/wx/getYouYanListData?leixin='+ leixin+'&type=' + type + '&reg=' + reg + '&area=' + area,
    method: 'get'
  })
}
export function getFeiYouZhi(type, reg, area, size, page) {
  return request({
    url: URL + '/api/wx/getFeiYouZhi?type=' + type + '&reg=' + reg + '&area=' + area + '&size=' + size + '&page=' + page,
    method: 'get'
  })
}
export function getCanChuData(type, reg, area, size, page) {
  return request({
    url: URL+'/api/wx/getCanChuData?type='+type+'&reg='+reg+'&area='+area + '&size='+size+'&page='+page,
    method: 'get'
  })
} 
export function getCheckList() {
  return request({
    url: URL + '/api/wx/getCheckNoList',
    method: 'get'
  })
}

