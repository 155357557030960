import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List } from 'antd-mobile';
import '../../main/index.css';
const Item = List.Item;

const Second = ({props, secondList }) => {
  
    const ItemClick = (value)=>{
        props.history.push('/detail/'+value.id+'');
    }
    return (
        
        <List className="my-list">
            {secondList != '' ?  secondList.map((item, index) => {
                return (
                    <Item className="item" key={index} onClick={()=>ItemClick(item)}>
                        <div className="contentm">
                            <div>{item.checkTime}</div>
                            <div className="status">{item.isStatus}</div>
                        </div>
                        <div className="contentm">
                            <div>频率</div>
                            <div>第{item.checkNumber}轮</div>
                        </div>
                        <div className="contentm">
                            <div>整改项</div>
                            <div>{item.checkFirst}</div>
                        </div>
                        <div className="contentm">
                            <div>查看详情</div>
                            <div className="status">></div>
                        </div>
                    </Item>
                )
            })
        :
            (<div>
            </div>)}
        </List>
    )
}

export default Second;