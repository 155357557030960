import 'antd-mobile/dist/antd-mobile.css';
import React, { useState } from 'react';
import { getWxCompanyInfo , getWxCU} from '../../api/wx';
import { Button, Modal,List,Toast, InputItem, WhiteSpace } from 'antd-mobile';
import './index.css';
const alert = Modal.alert;
const Login = (props) => {
    const [code, setCode] = useState('');
    const [tel, setTel] = useState('');

    const onChange = (value) => {
        setCode(value);
    }
    const onChangePhone =(value) =>{
        setTel(value);
    }
    const onClick = (value) => {
        if(!code || !tel){
            Toast.info('请输入信息',2);
        }else{
            getWxCompanyInfo(code,tel).then(info => {
                if(info['data']){
                    const alertInstance = alert('绑定公司', info['data'].companyName, [
                            { text: '取消', onPress: () => alertInstance.close(), style: 'default' },
                            { text: '确认绑定', onPress: () => {
                                //绑定网路请求 ---首先获取之前存在本地的微信用户的信息
                                const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
                                let openid = wxInfo.openid;
                                let unionid = wxInfo.unionid;
                                let compayId = info['data'].id;
                                getWxCU(openid,unionid,compayId).then(data =>{
                                    //返回的信息
                                    localStorage.setItem("WxUserInfo",JSON.stringify(data['data'].userInfo))
                                    localStorage.removeItem("companyInfo");
                                    if (data.companyInfo) {
                                        localStorage.setItem("companyInfo",JSON.stringify(data['data'].companyInfo))
                                    }
                                    //弹窗取消
                                    props.history.goBack();//返回到主页
                                    alertInstance.close()
                                })
                            }},
                        ]);
                }else{
                     const alertInstance = alert('提示', '暂无公司信', [
                            { text: 'Cancel', onPress: () => alertInstance.close(), style: 'default' },
                            { text: 'OK', onPress: () => alertInstance.close() },
                        ]);
                }
            });
        }
       
    }

    return (
        <div>
            <div style={{ "margin": "10px" }}>请输入绑定店铺信息:</div>
            <List>
                <InputItem
                    style={{ "fontSize": "18px" }}
                    clear
                    onChange={onChange}
                    placeholder="统一社会信用代码(后6位)"></InputItem>
                <InputItem
                    style={{ "fontSize": "18px" }}
                    clear
                    onChange={onChangePhone}
                    placeholder="负责人手机号码"></InputItem>
                <List.Item>
                    <Button style={{ "fontSize": "15px" }} type="primary" onClick={onClick}>提交</Button><WhiteSpace />
                </List.Item>
            </List>
        </div>
    )
}

export default Login;