import { Component } from 'react'
import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List, Button, Image, ListView, div, SearchBar, Icon, Pagination, Badge, Toast, Tabs, Picker } from 'antd-mobile';
import NormalBarChart from './compent/echatzhu';
import { getYouShuiListData, getYouYanListData, getQuYu } from '../../api/wx';
import "./index.css";
const Item = List.Item;
const Brief = Item.Brief;
const tab = [
    { title: <Badge >设备点检</Badge>, sub: 'subcontent' },
    { title: <Badge >设备保养</Badge>, sub: 'subcontent' },
    { title: <Badge >设备维修</Badge>, sub: 'subcontent' },
];
const tabs = [
    { title: <Badge >已登记</Badge>, sub: 'subcontent' },
    { title: <Badge >未登记</Badge>, sub: 'subcontent' },
];

const tabs1 = [
    { title: <Badge >日</Badge>, sub: 'subcontent' },
    { title: <Badge >周</Badge>, sub: 'subcontent' },
    { title: <Badge >月</Badge>, sub: 'subcontent' },
    { title: <Badge >年</Badge>, sub: 'subcontent' },
];
export default class js extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dianji1:0,
            reg: "已登记",
            dianji2: 0,
            type: "day",
            chengshilist: [],
            area: '',
            shuju: [],
            shul: 0,
            cishu:0,
            leixin:0
        };
    }
    checkT = (tab,index) =>{
        let str = ""
        // if (index == 0) {
        //     str = index
        // }
        // if (index == 1) {
        //     str = index
        // }
        // if (index == 2) {
        //     str = index
        // }
        this.setState({
            dianji: index,
            leixin: index,
        }, () => {
            this.getData()
        })
    }
    checkTab = (tab, index) => {
        this.setState({
            dianji1: index,
            reg: index == 0 ? "已登记" : "未登记"
        }, () => {
            this.getData()
        })
    }
    checkTab1 = (tab, index) => {
        var leixing = ""
        if (index == 0) {
            leixing = "day"
        }
        if (index == 1) {
            leixing = "week"
        }
        if (index == 2) {
            leixing = "mon"
        }
        if (index == 3) {
            leixing = "year"
        }
        this.setState({
            dianji2: index,
            type: leixing,
        }, () => {
            this.getData()
        })
    }
    componentDidMount() {
        let list = ['全部', '仓汇路', '玉树路', '仓丰路', '松汇西路', '仓华路', '永丰路', '玉秀路', '富永路', '富强路', '草长浜路', '云逸路', '乐都西路', '荣乐西路', '松江南站', '金玉路', '新理想广场', '三辰苑商业⼴场', '时代广场', '⻜航广场', '兴益农贸市场', '梅园埭路', '三新北路', '三新汇开元里', '中山西路']
        let chengshilist = [];
        for (let i = 0; i < list.length; i++) {
            chengshilist.push({
                label: list[i],
                value: list[i]
            })
        }
        this.setState({
            chengshilist: chengshilist
        })
        this.getData() //获取数据列表
    }
    //获取区域列表
    // chengshi = () => {
    //     getQuYu().then(data => {
    //         if (data.errno == 0) {
    //             var list = data.data
    //             var chengshilist = []
    //             for (let i = 0; i < list.length; i++) {
    //                 chengshilist.push({
    //                     label: list[i],
    //                     value: list[i]
    //                 })
    //             }
    //             this.setState({
    //                 chengshilist: chengshilist
    //             })
    //         }
    //     })
    // }
    //修改区域回调
    onChangeArea = (e) => {
        this.setState({
            area: e
        }, () => {
            this.getData()
        })
    }
    //获取数据列表
    getData = () => {
        const { leixin, type, reg, area } = this.state
		this.setState({
			biaogxianshi:false
		})
        console.log('================',leixin,type,reg,area);
        getYouYanListData(leixin,type, reg, area).then(data => {
            this.setState({
                // leixin:0,
                shuju:[],
                shul:0,
                cishu:0
            })
            if (data.errno == 0) {
                console.log("data==", data.data)
                //计算   清理多少家 清理次数  清理数量列表
                let list = data.data;
                if (reg == "未登记") {
                    if (list.length > 0) {
                        //首先计算清理多少家
                        let ary = [], arry = [], array = [];
                        for (let i = 0; i < list.length; i++) {
                            if (ary.indexOf(list[i].companyId) == -1) {
                                ary.push(list[i].companyId)//一共有多少家
                            }
                            // let obj = {
                            //     "companyOldName": list[i].company['companyOldName'],
                            //     "companyName": list[i].companyName,
                            //     // "clearNum": '1'
                            // }
                            // array.push(obj);//列表数据
                            arry.push(list[i].companyId)//一共有多少次
                        }
                        this.setState({
                            shul: ary.length,
                            cishu: arry.length,
                            shuju: list
                        })
                    }
                } else {
                    if (list.length > 0) {
                        //首先计算清理多少家
                        let zunl = list.length;
                        let ary = [], arry = [], array = [];
                        //list表示整个数据
                        //ary 表示 去重后的所有的公司集合
                        //
                        console.log('返回数据长度为====',list.length);
                        for (let i = 0; i < list.length; i++) {
                            if (ary.indexOf(list[i].companyId) == -1) {
                                ary.push(list[i].companyId)
                                arry.push({
                                    "companyId": list[i].companyId,
                                    // "clearNum": list[i].clearNum,
                                    "company": list[i].company
                                })//一共有多少家
                            }
                        }
                        console.log('ary====',ary.length);
                        //ary 就是去重后的公司id集合
                        let gry = [];
                        for (let i = 0; i < arry.length; i++) {// ary没有重复的公司id集合 [1,2,3,4,5,6,7,8]
                            
                            let t = 0;
                            for (let j = 0; j < list.length; j++) {//有重复的所有数据集合 [1,2,2,2,3,1,4,5,5,6,7,7,5,8,8]
                                if (arry[i].companyId == list[j].companyId) {
                                    t = t + 1
                                }
                            }
                            let ob = {
                                "companyId": arry[i].companyId,
                                "clearNum": t,
                                "company": arry[i].company
                            }
                            gry.push(ob);
                        }
                        console.log('gry===',gry);//最终需要渲染的列表数据

                        this.setState({
                            shul: arry.length,//一共有多少家公司
                            cishu: zunl,//这些公司合计清理了多少次
                            shuju: gry
                        })
                    }
                }
                
            }
        })
    }
    render() {
        const { dianji, dianji1, reg, dianji2,cishu, chengshilist, area, shuju, shul } = this.state
        console.log("dianji1===", dianji1)
        return (
            <div className="boxCon">

                <Tabs className="mytab" tabs={tab} page={dianji}
                    onChange={(tab, index) => this.checkT(tab, index)}
                    renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
                    renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
                    tabBarUnderlineStyle={{ width: '30px', marginLeft: '15%', background: '#1491ED' }}
                >
                </Tabs>


                <Tabs className="mytab" tabs={tabs} page={dianji1}
                    onChange={(tab, index) => this.checkTab(tab, index)}
                    renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
                    renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
                    tabBarUnderlineStyle={{ width: '30px', marginLeft: '22%', background: '#1491ED' }}
                >
                </Tabs>

                <Tabs className="mytab" tabs={tabs1} page={dianji2}
                    onChange={(tab, index) => this.checkTab1(tab, index)}
                    renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
                    renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
                    tabBarUnderlineStyle={{ width: '30px', marginLeft: '9%', background: '#1491ED' }}
                >

                </Tabs>

                <List className="list">

                    <Picker cols={1}
                        onChange={this.onChangeArea}
                        value={area} data={chengshilist} className="forss">
                        <List.Item arrow="horizontal">区域名称</List.Item>
                    </Picker>
                    {/* <Item extra={<div>555</div>} >登记时间</Item> */}
                    {
                        dianji1 == 0 ? <Item extra={shul + "家"} align="bottom">清理数量</Item> : null
                    }
                    {
                        dianji1 == 0 ? <Item extra={cishu + "次"} align="bottom">清理次数</Item> : null
                    }
                    {
                        dianji1 == 1 ? <Item extra={shul + "家"} align="bottom">未清理数量</Item> : null
                    }
                    {
                        dianji1 == 1 ? <Item extra={cishu + "次"} align="bottom">未清理次数</Item> : null
                    }


                </List>
                <div className='history-table-wrap'>
                    <div className="table">
                        <div className="tr">
                            <div className="th th1">序号</div>
                            <div className="th th2">店铺名称</div>
                            <div className="th th3">企业名称</div>
                            {
                                reg == '已登记' ?
                                    <div className="th th4">清理数量</div>
                                    :
                                    ''
                            }
                        </div>

                        {
                            shuju.length == 0 ?
                                <div className="nodata" >
                                    暂无数据
                                </div> :
                                shuju.map((item, index) => {
                                    return (
                                        <div className="tr" key={index}>
                                            <div className="td td1">{index + 1}</div>
                                            <div className="td td2">{item.company.companyOldName}</div>
                                            <div className="td td3">{item.company.companyName}</div>
                                            {
                                                reg == '已登记' ?
                                                    <div className="td td4">{item.clearNum}</div>
                                                    :
                                                    ''
                                            }
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
            </div>
        )
    }
}


