import 'antd-mobile/dist/antd-mobile.css';
import React, { useState , useEffect } from 'react';
import {getInfoWxOut,getWxInfo} from '../../api/wx';
const Local = (props) => {
    useEffect(() => {
        const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
        getInfoWxOut(wxInfo.openid).then(data =>{
            if(data['data']){
                getWxInfo().then(info => {//这个info里面是没有任何公司信息
                    localStorage.setItem(
                      "WxUserInfo",
                      JSON.stringify(info['data'].userInfo)
                    );
                    localStorage.removeItem("companyInfo");
                    if (info['data'].companyInfo) {
                      localStorage.setItem(
                        "companyInfo",
                        JSON.stringify(info['data'].companyInfo)
                      );
                    }
                    props.history.push('/login');//跳到登录页
                  });
            }
        })
    },[]);
    return (
        <div>
           已清除数据
        </div>
    )
}

export default Local;