import axios from 'axios'
import { getToken,getAdminToken } from './auth'
import errorCode from './errorCode'
import { Toast } from 'antd-mobile';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['platform'] = 'wxmp'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000
})
// request拦截器
service.interceptors.request.use(config => {
  console.log(getToken() ? getToken() : getAdminToken());
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if ((getToken() || getAdminToken()) && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + (getToken() ? getToken() : getAdminToken()) // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
  console.log('====',res.data);
    // 未设置状态码则默认成功状态
    const code = res.data.errno || 0;
    // 获取错误信息
    const message = errorCode[code] || res.data.message || errorCode['default']
    // if (code != 0) {
    //     localStorage.clear();
    //     Toast.info(message,1)
    // } 
    // else if (code === 500) {
    //     Toast.info(message,1)
    //   return Promise.reject(new Error(message))
    // } else if (code !== 200) {
    //     Toast.info(message,1)
    //   return Promise.reject('error')
    // } 
    // else {
      return res.data
    // }
  },
  error => {
    console.log('err' + error)
    Toast.info(error.message,5)
    return Promise.reject(error)
  }
)

export default service
