import React, { useEffect } from 'react';
import * as echarts from "echarts";

const Index = (props) => {
    const operationObj = {};
    operationObj[props.chartId + 'Index'] = 0;
    operationObj[props.chartId + 'Interval'] = undefined;
    operationObj[props.chartId + 'Chart'] = undefined;

    useEffect(() => {
        if (operationObj[props.chartId + 'Chart'] != null && operationObj[props.chartId + 'Chart'] != "" && operationObj[props.chartId + 'Chart'] != undefined) {
            operationObj[props.chartId + 'Chart'].dispose();
        }

        operationObj[props.chartId + 'Chart'] = echarts.init(document.getElementById(props.chartId));
        operationObj[props.chartId + 'Chart'].setOption(getChartOption());
        return componentWillUnmount;
    }, []);

    function componentWillUnmount() {
        if (operationObj[props.chartId + 'Interval']) {
            clearInterval(operationObj[props.chartId + 'Interval']);
        }
        if (operationObj[props.chartId + 'Chart'] && operationObj[props.chartId + 'Chart'].id) {
            operationObj[props.chartId + 'Chart'].dispose();
        }
    }

    function getChartOption() {
        console.log("----------接受数据---List1---------", props.List);
        return {
            title: {
                show: true,
                text: props.title,
                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    color: "#000"
                },
            },
            xAxis: {
                type: 'category',
                data: [1,2,3,4,5,6,7,8,9,10,11,12]
            },
            yAxis: {
                type: 'value'
            },
            series: [{
                data: props.List,
                type: 'line',
                smooth: true
            }]
        }
    }

    return (
        <div className="normal-box p20">
            <div id={props.chartId} style={{ width: '100%', height: '300px' }}>
            </div>
        </div>
    )
}
export default Index;
