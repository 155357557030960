import 'antd-mobile/dist/antd-mobile.css';
import React, { useEffect, useState } from 'react';
import { Card, WingBlank, ImagePicker, Toast, Steps, Modal, TextareaItem,Button, WhiteSpace } from 'antd-mobile';
import { getCheckContentInfo, setSlMessage,wxupload,deletephone } from '../../api/wx';
import { dataURLtoBlob,convertBase64UrlToImgFile } from '../../util/wxService';
import ReactLoading from 'react-loading';
import './index.css';
const Step = Steps.Step;
var imgs = [];
var imgtemp = [];
const Detail = (props) => {
    var [Info, setInfo] = useState('');
    var [msg, setMsg] = useState([]);
    var [text, setText] = useState('');
    var [wgimgList, setWgImgList] = useState([]);
    var [imgList, setImgList] = useState([]);

    console.log('路由传过来的',Info);
    
    useEffect(() => {
        //获取检查项目的详情
        if(!Info){
            getCheckContentInfo(props.match.params.id).then((data) => {
                console.log(data);
                setInfo(data['data'].Info);//基础信息
                if(data['data'].Info.images != null && data['data'].Info.images != ""){
                    console.log('-----------------------------');
                    let array = [];
                    let ary = data['data'].Info.images.split(',')
                    for(let i = 0; i < ary.length;i++){
                        array.push({
                            "id":ary[i],
                            "url":"https://yfadmin.chuanfu.ltd/profile/"+ary[i],
                        })
                    }
                    console.log('array=',array);
                    setWgImgList(array);
                }
                setMsg(data['data'].msg);//对话信息
            })
        }
    },[])

    //图片上传
    const onAddImageClick =(value)=>{
        console.log('---onAddImageClick---',value[0].url);
    }
    //单击图片事件
    const onImageClick =(index,fs)=>{
        console.log(index, fs)

    }
    const onChange=(files,type,index)=>{
        console.log('---onchang---',files);
        if(type == 'add'){
            // Toast.info(files.length);
            //添加图片
            
            var formData = new FormData();
            formData.append("imgData",files[files.length-1].url);
            Toast.info('请稍等,上传中...',1)
            wxupload(formData).then((data)=>{
                imgtemp.push(data['data'].aliasName)
                setImgList(files);
            })
        }else if(type == 'remove'){
            setImgList(files);//删除图片
            //删除图片
            var formData = new FormData();
            formData.append("fileId",imgtemp[index].split(".")[0]);
            deletephone(formData).then((data)=>{
                Toast.info('已删除',1)
                imgtemp.splice(index,1);
            })
        }
       
    }

    const textChang =(value)=>{
        setText(value);
    }

    const sumit =(value)=>{
        if(!text){
            Toast.info('请输入信息',1);
            return;
        }
        //提交回复
        setSlMessage({"peopleToweb":text,"id":Info.id,"status":"待审批","images":imgtemp.length > 0 ? imgtemp.join(','):''}).then((data)=>{
            if(data['data']){
                Toast.info('已提交', 1);
                props.history.push('/');//跳到主页
            }
        })

    }
    return (
       <div>
           {
                Info ? <div>
                    <div className="title">企业信息</div>
                    <WingBlank size="md">
                        <Card>
                            <div className="contentd">
                                <div className="tvs">店铺名称</div>
                                <div>{Info.companyName}</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">频率</div>
                                <div>第{Info.checkNumber}次</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">检查日期</div>
                                <div>{Info.checkDate}</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">负责人</div>
                                <div>{Info.checkName}</div>
                            </div>
                        </Card>
                    </WingBlank>
                    <div className="title">整改问题点</div>
                    <WingBlank size="md">
                        <Card>
                            <div className="contentd">
                                <div className="tvss">{Info.checkThree}</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">截止整改日</div>
                                <div>{Info.checkTime}</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">严重程度</div>
                                <div>{Info.checkLevel}</div>
                            </div>
                            <div className="contentd">
                                <div className="tvs">提出要求</div>
                                <div>{Info.checkRequest}</div>
                            </div>
                            <div className="contentcol">
                                <div className="tvs">违法法规</div>
                                <div className="law">{Info.checkLaws}</div>
                            </div>

                        </Card>
                    </WingBlank>
                    {wgimgList.length > 0 ? (
                        <WingBlank size="md" className="wg">
                            <Card>
                                <div className="contentcol">
                                    <div className="tvs">违规图片</div>
                                    <ImagePicker
                                        files={wgimgList}
                                        selectable={false}
                                        disableDelete={true}
                                        onImageClick={onImageClick}
                                    />
                                </div>
                            </Card>
                        </WingBlank>
                    ) : ''}
                    {msg.length > 0 ? (
                        <div>
                            <div className="title">整改进度</div>
                            {
                                msg.map((item, index) => {
                                    let data = item.images != null ? item.images.split(',').map((e, index) => {
                                        return { "url": "https://yfadmin.chuanfu.ltd/profile/" + e, "id": index };
                                    }) : [];
                                    return (
                                        <WingBlank size="md" className="wg" key={index}>

                                            <Card className="st">
                                                <ImagePicker
                                                    files={data}
                                                    disableDelete={true}
                                                    selectable={false}
                                                    accept="image/gif,image/jpeg,image/jpg,image/png"
                                                >
                                                </ImagePicker>
                                                <div className="peop">商家回复:</div>
                                                <div>{item.peopleToweb}</div>
                                                <div className="peop">检查人员回复:</div>
                                                <div>{item.webToPeople}</div>
                                            </Card>
                                        </WingBlank>
                                    )
                                })
                            }

                        </div>
                    ) : ''}

                    <div className="title">整改照片</div>
                    <WingBlank size="md" className="wg">
                        <Card className="st">
                            <ImagePicker
                                files={imgList}
                                onChange={onChange}
                                onImageClick={onAddImageClick}
                                accept="image/gif,image/jpeg,image/jpg,image/png"
                            />
                        </Card>
                    </WingBlank>
                    <div className="title">整改回复</div>
                    <WingBlank size="md" className="wg">
                        <Card className="st">
                            <TextareaItem
                                placeholder="请输入整改回复"
                                data-seed="logId"
                                // className="textarea"
                                clear={true}
                                onChange={textChang}
                                rows={5}

                            />
                            <Button className="btn" type="primary" onClick={sumit}>提交</Button>
                        </Card>
                    </WingBlank>
                </div>
               :
                <ReactLoading className="loading" type="spinningBubbles" color="#171717" width={100} height={100}/>
           }
       </div>
    )
}

export default Detail;