import 'antd-mobile/dist/antd-mobile.css';
import React, { useState } from 'react';
const Check = (props) => {
    return (
        <div>
           点检页面待开发
        </div>
    )
}

export default Check;