import { Component } from 'react'
import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { List, Button, Image, ListView, div, SearchBar, Icon, Pagination, Badge, Toast, Tabs, Picker } from 'antd-mobile';
import NormalBarChart from './compent/echatzhu';
import { getQuYu, getFeiYouZhi } from '../../api/wx';
import "./index.css";
const Item = List.Item;
const Brief = Item.Brief;
const tabs = [
    { title: <Badge >已登记</Badge>, sub: 'subcontent' },
    { title: <Badge >未登记</Badge>, sub: 'subcontent' },
];

const tabs1 = [
    { title: <Badge >日</Badge>, sub: 'subcontent' },
    { title: <Badge >周</Badge>, sub: 'subcontent' },
    { title: <Badge >月</Badge>, sub: 'subcontent' },
    { title: <Badge >年</Badge>, sub: 'subcontent' },
];
export default class js extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dianji1: 0,
            over: '1',
            reg: "已登记",
            dianji2: 0,
            type: "day",
            title: '点击加载更多....',
            chengshilist: [],
            area: [],
            List: [],
            shuju: [],
            zunl: 0,
            shul: 0,
			lzhou:["第一周","第二周","第三周","第四周"],
			lyue:["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","12","13","24","25","26","27","28","29","30","31"],
			lnian:["1","2","3","4","5","6","7","8","9","10","11","12"],
			carxlist:[],
			carslist:[],
            size: 80,//每次请求多少条
            page: 1  //第几页，默认第一页
        };
    }
    //一级tab切换
    checkTab = (tab, index) => {
        this.setState({
            dianji1: index,
            List: [],
            size: 50,
            page: 1,
            reg: index == 0 ? "已登记" : "未登记"
        }, () => {
            this.getData()
        })
    }
     //二级tab切换
    checkTab1 = (tab, index) => {
        var leixing = ""
        if (index == 0) {
            leixing = "day"
            this.setState({
                over: 1
            })
        }
        if (index == 1) {
            leixing = "week"
            this.setState({
                over: 7
            })
        }
        if (index == 2) {
            leixing = "mon"
            this.setState({
                over: 30
            })
        }
        if (index == 3) {
            leixing = "year"
            this.setState({
                over: 365
            })
        }
        this.setState({
            dianji2: index,
            type: leixing,
            List: [],
            size: 50,
            page: 1
        }, () => {
            this.getData()
        })
    }
    componentDidMount() {
        let list = ['全部', '仓汇路', '玉树路', '仓丰路', '松汇西路', '仓华路', '永丰路', '玉秀路', '富永路', '富强路', '草长浜路', '云逸路', '乐都西路', '荣乐西路', '松江南站', '金玉路', '新理想广场', '三辰苑商业⼴场', '时代广场', '⻜航广场', '兴益农贸市场', '梅园埭路', '三新北路', '三新汇开元里', '中山西路']
        let chengshilist = [];
        for (let i = 0; i < list.length; i++) {
            chengshilist.push({
                label: list[i],
                value: list[i]
            })
        }
        this.setState({
            chengshilist: chengshilist
        })
        // this.chengshi()//获取区域接口
        this.getData()//获取整个列表数据接口
    }
    addMore = (e) => {
        console.log('------');
        this.setState({
            page: this.state.page + 1
        }, () => {
            this.getData()
        })
    }
    // chengshi = () => {
    //     getQuYu().then(data => {
    //         if (data.errno == 0) {
    //             var list = data.data
    //             var chengshilist = []
    //             for (let i = 0; i < list.length; i++) {
    //                 chengshilist.push({
    //                     label: list[i],
    //                     value: list[i]
    //                 })
    //             }
    //             this.setState({
    //                 chengshilist: chengshilist
    //             })
    //         }
    //     })
    // }
    onChangeArea = (e) => {
        this.setState({
            area: e,
            List: [],
            size: 80,
            page: 1,
        }, () => {
            this.getData()
        })
    }
    getData = () => {
        const { size, page, type, reg, area } = this.state
		this.setState({
			biaogxianshi:false,
            shuju: [],
            shul: 0,
            zunl: 0
		})
        getFeiYouZhi(type, reg, area, size, page).then(data => {
            this.setState({
                List: this.state.List.concat(data.data),
                title: data.data.length == 0 ? '没有数据了' : '点击加载更多...'
            })

            if (data.errno == 0) {
                //计算   清理多少家 清理次数  清理数量列表
                let list = this.state.List;
                if (reg == "未登记") {
                    if (list.length > 0) {
                        //首先计算清理多少家
                        let ary = [], arry = [], array = [];
                        for (let i = 0; i < list.length; i++) {
                            if (ary.indexOf(list[i].companyId) == -1) {
                                ary.push(list[i].companyId)//一共有多少家
                            }
                        }
                        this.setState({
                            shul: ary.length,
                            shuju: list
                        })
                    }
                } else {
                    if (list.length > 0) {
                        //首先计算清理多少家
                        let zunl = 0;
                        let ary = [], arry = [], array = [];
                        for (let i = 0; i < list.length; i++) {
                            if (ary.indexOf(list[i].companyId) == -1) {
                                ary.push(list[i].companyId)
                                arry.push({
                                    "companyId": list[i].companyId,
                                    "garbageNnit": list[i].garbageNnit,
                                    "company": list[i].company
                                })//一共有多少家
                            }
                            zunl = (zunl * 1) + (list[i].garbageTd * 1)//每条数据garbageTd累计相加
                        }
                        //ary 就是去重后的公司id集合
                        let gry = [];
                        for (let i = 0; i < arry.length; i++) {//没有重复的公司id集合
                            let t = 0;
                            for (let j = 0; j < list.length; j++) {//有重复的所有数据集合
                                if (arry[i].companyId == list[j].companyId) {
                                    t = (t * 1) + (list[j].garbageTd * 1)
                                }
                            }
                            let ob = {
                                "companyId": arry[i].companyId,
                                "garbageTd": t,
                                "garbageNnit": arry[i].garbageNnit,
                                "company": arry[i].company
                            }
                            gry.push(ob);
                        }

                        this.setState({
                            zunl: zunl,
                            shul: ary.length,//一共有多少家店铺有登记---需要去重
                            shuju: gry
                        })
                    }
                }
            }
        })
    }
    render() {
        const { dianji1, title, dianji2, type, biaogxianshi, reg, zunl, chengshilist, area,carslist, shuju,lzhou,lyue,lnian, shuliang, shul } = this.state
        console.log("dianji1===", dianji1)
        return (
            <div className="boxCon">
                <div className="head">
                    <Tabs className="mytab" tabs={tabs} page={dianji1}
                        onChange={(tab, index) => this.checkTab(tab, index)}
                        renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
                        renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
                        tabBarUnderlineStyle={{ width: '30px', marginLeft: '22%', background: '#1491ED' }}
                    >
                    </Tabs>

                    <Tabs className="mytab" tabs={tabs1} page={dianji2}
                        onChange={(tab, index) => this.checkTab1(tab, index)}
                        renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
                        renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
                        tabBarUnderlineStyle={{ width: '30px', marginLeft: '9%', background: '#1491ED' }}
                    >

                    </Tabs>

                    <List className="list">

                        <Picker cols={1}
                            onChange={this.onChangeArea}
                            value={area} data={chengshilist} className="forss">
                            <List.Item arrow="horizontal">区域名称</List.Item>
                        </Picker>
                        {/* <Item extra={<div>555</div>} >登记时间</Item> */}
                        {
                            dianji1 == 0 ? <Item extra={shul + '家'} align="bottom">登记数量</Item> : null
                        }
                        {
                            dianji1 == 0 ? <Item extra={zunl + "kg"} align="bottom">登记总量</Item> : null
                        }

                        {
                            dianji1 == 1 ? <Item extra={shul + '家'} align="bottom">未登记数量</Item> : null
                        }


                    </List>
                </div>
                <div className='history-table-wrap' style={{ "marginTop": "225px" }}>
                    <div className="table">
                        <div className="tr">
                            <div className="th th1">序号</div>
                            <div className="th th2">店铺名称</div>
                            <div className="th th3">企业名称</div>
                            <div className="th th4">收运单位</div>
                            {
                                reg == '已登记' ?
                                    <div className="th th5">数量</div>
                                    :
                                ''
                            }
                            
                        </div>

                        {
                            shuju.length == 0 ?
                                <div className="nodata" >
                                    暂无数据
                                </div> :
                                shuju.map((item, index) => {
                                    return (
                                        <div className="tr">
                                            <div className="td td1">{index + 1}</div>
                                            <div className="td td2">{item.company.companyOldName}</div>
                                            <div className="td td3">{item.company.companyName}</div>
                                            <div className="td td4">{reg == '已登记' ? item.garbageNnit : item.unit}</div>
                                           {
                                               reg == '已登记' ?
                                                    <div className="td td5">{item.garbageTd + 'kg'}</div>
                                                    :
                                                    ''
                                           }
                                        </div>
                                    )
                                })
                        }

                    </div>
                </div>
                {
                    reg == '已登记' ?
                        <div className="jiazai" onClick={this.addMore}>{title}</div>
                        :
                        ''
                }
                {/* 趋势图 */}
				{/* {
					type == "week"&& biaogxianshi ?
                <NormalBarChart chartTitle={'店铺规模'} api={'company/getLeftOneList'} chartId={'storeSizeChart'}
                    chartAxisData={lzhou}  carslist={carslist}/> :null
				} 
				{
					type == "mon"&& biaogxianshi ?
				<NormalBarChart chartTitle={'店铺规模'} api={'company/getLeftOneList'} chartId={'storeSizeChart'}
				    chartAxisData={lyue}  carslist={carslist}/> :null
				} 
				{
					type == "year"&& biaogxianshi ?
				<NormalBarChart chartTitle={'店铺规模'} api={'company/getLeftOneList'} chartId={'storeSizeChart'}
				    chartAxisData={lnian}  carslist={carslist}/> :null
				} 
                <Button className="btn" type="primary">下载图表</Button> */}

            </div>
        )
    }
}


