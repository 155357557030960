import 'antd-mobile/dist/antd-mobile.css';
import React, { useState } from 'react';
import { getWxCompanyInfo, adminLogin, getWxCU} from '../../api/wx';
import { Button, Modal,List,Toast, InputItem, WhiteSpace } from 'antd-mobile';
import './index.css';
import ReactLoading from 'react-loading';
const alert = Modal.alert;
const AdminLogin = (props) => {
    const [code, setCode] = useState('');
    const [tel, setTel] = useState('');
    const [loading, setLoding] = useState(false);

    //用户名输入框监听
    const onChange = (value) => {
        setCode(value);
    }
    //密码输入框监听
    const onChangePhone =(value) =>{
        setTel(value);
    }
    const onClick = (value) => {
        if(!code || !tel){
            Toast.info('请输入信息',2);
        } else {
            setLoding(true);
            var formData = new FormData();
            formData.append("userName",code);
            formData.append("passWord", tel);
            formData.append("type", "account");
            adminLogin(formData).then((data) => {
                console.log('登陆信息====',data);
                setLoding(false)
                if(data['errno'] === 0){
                    //登陆成功
                    console.log(data['data'].token);
                    localStorage.setItem("adminToken", data['data'].token);
                    // props.history.push('/home');//跳转到某个页面
                    props.history.goBack();//返回到主页
                }else{
                    //登陆失败
                    Toast.info('密码错误', 2);
                }
            })
        }
       
    }

    return (
        <div>
            {
            loading ? 
                <ReactLoading className = "loading" type = "spinningBubbles" color = "#171717" width = { 100 } height = { 100 } />
                :
                    <div>
                        <div style={{ "margin": "10px" }}>管理员登陆:</div>
                        <List>
                            <InputItem
                                style={{ "fontSize": "18px" }}
                                clear
                                onChange={onChange}
                                placeholder="请输入管理员用户名"></InputItem>
                            <InputItem
                                style={{ "fontSize": "18px" }}
                                clear
                                onChange={onChangePhone}
                                placeholder="请输入管理员密码"></InputItem>
                            <List.Item>
                                <Button style={{ "fontSize": "15px" }} type="primary" onClick={onClick}>登陆</Button><WhiteSpace />
                            </List.Item>
                        </List>
                    </div>
        }
        </div>
        
       
    )
}

export default AdminLogin;