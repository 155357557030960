import 'antd-mobile/dist/antd-mobile.css';
import React, { useState, useEffect } from 'react';
import { getCheckContent, getWxCU } from '../../api/wx';
import { Badge,Toast, ImagePicker, Tabs } from 'antd-mobile';
import { getWxCode } from '../../util/wxService'
import First from './first'
import Second from './second'
import Three from './three'
import "./index.css";

const tabs = [
    { title: <Badge >全部</Badge>, sub: 'subcontent' },
    { title: <Badge >整改中</Badge>, sub: 'subcontent' },
    { title: <Badge >逾期未整改</Badge>, sub: 'subcontent' },
];
var type = 'a';
const Main = (props) => {
    const [wxInfo, setWxInfo] = useState('');
    const [firstList, setFirstList] = useState([]);
    const [secondList, setSecondList] = useState([]);
    const [threeList, setThreeList] = useState([]);
    useEffect(() => {
            if (localStorage.getItem("WxUserInfo") != null && localStorage.getItem("WxUserInfo") != undefined) {
                const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
                console.log('-----------------------------------');
                console.log(wxInfo);
                if (!wxInfo.companyId) {
                    props.history.push('/login');//跳到登录页
                } else {
                    setWxInfo(wxInfo);
                    //进入页面默认请求第一页数据
                    getCheckContent(wxInfo['companyId'],type).then((data) => {
                        setFirstList(data['data'] ? data['data'] : []);
                    })
                }
            }else {
             //授权登录
                getWxCode(() => {
                    if (localStorage.getItem("WxUserInfo") != null && localStorage.getItem("WxUserInfo") != undefined) {
                        const wxInfo = JSON.parse(localStorage.getItem("WxUserInfo"));
                        if (!wxInfo.companyId) {
                            props.history.push('/login');//跳到登录页
                        } else {
                            setWxInfo(wxInfo);
                            //进入页面默认请求第一页数据
                            getCheckContent(wxInfo['companyId'],type).then((data) => {
                                setFirstList(data['data'] ? data['data'] : []);
                            })
                        }
                    }
                })
            }
    }, [])

    const checkTab = (tab, index) => {
        if (index == 0) {
            type = 'a';
            getCheckContent(wxInfo['companyId'],type).then((data) => {
                setFirstList(data['data'] ? data['data'] : []);
            })

        } else if (index == 1) {
            type = 'b';
            getCheckContent(wxInfo['companyId'],type).then((data) => {
                setSecondList(data['data'] ? data['data'] : []);
            })
        } else if (index == 2) {
            type = 'c';
            getCheckContent(wxInfo['companyId'],type).then((data) => {
                setThreeList(data['data'] ? data['data'] : []);
            })
        }
    }
    return (
        <Tabs className="mytab" tabs={tabs} initialPage={0}
            onChange={(tab, index) => checkTab(tab, index)}
            renderTab={tab => <span>{tab.title}-{tab.sub}</span>}
            renderTabBar={(props) => <Tabs.DefaultTabBar {...props} />}
        >
            <First props={props} firstList={firstList}/>
            <Second props={props} secondList={secondList}/>
            <Three />
        </Tabs>
    )
}

export default Main;